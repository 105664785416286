body {
    //font-family: 'Roboto Condensed';
    font-size: $vs-general-font-size;
}

body.landing-body {
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin-top: 0;
    margin-bottom: 20px;
}
// p{
//     margin-top: 0;
//     margin-bottom: 0;
// }

input,
textarea,
button,
select {
    outline: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    outline: none;
    text-decoration: none;
}

body .ui-widget,
body .ui-widget .ui-widget {
    font-family: inherit;
}


$rangeMarginPadding : (0, 5, 10, 15, 20, 25, 30, 35, 40, 50);

/**
@Padding
*/
@include generate-class(m, margin, $rangeMarginPadding);
@include generate-class(mt, margin-top, $rangeMarginPadding);
@include generate-class(mr, margin-right, $rangeMarginPadding);
@include generate-class(mb, margin-bottom, $rangeMarginPadding);
@include generate-class(ml, margin-left, $rangeMarginPadding);
@include generate-class(pd, 'padding', $rangeMarginPadding);
@include generate-class(pt, padding-top, $rangeMarginPadding);
@include generate-class(pr, padding-right, $rangeMarginPadding);
@include generate-class(pb, padding-bottom, $rangeMarginPadding);
@include generate-class(pl, padding-left, $rangeMarginPadding);

/**
@border-radius
*/
@include generate-class(br, border-radius, (3, 5, 6, 8, 10, 15, 20, 25));

.border-1 {
	border: 1px solid var(--clr-common-gray3);
}

.box-shadow-0 {
	box-shadow: unset !important;
}

.flex-1 {
	flex: 1;
}