body .ui-treetable .ui-treetable-thead>tr>th {
    color: #fff;
}

body .ui-treetable .ui-treetable-tbody>tr.ui-contextmenu-selected {
    background-color: #f5f5f5;
    color: #666;
}

body .ui-treetable .ui-treetable-thead>tr>th {
    color: #fff;
}

body .ui-treetable .ui-treetable-tbody>tr.ui-contextmenu-selected {
    background-color: #f5f5f5;
    color: #666;
}

// body .ui-treetable .ui-treetable-tbody tr {
//     &:hover {
//         background-color: #ffffeb;
//     }
// }
body .ui-treetable .ui-treetable-tbody tr.row-selected {
    background-color: #eaf5ff;
}

body .ui-treetable .ui-treetable-thead>tr>th,
body .ui-treetable .ui-treetable-tbody>tr>td,
body .ui-treetable .ui-treetable-tfoot>tr>td {
    padding: 8px 5px;
    outline: none;
}

// p-table.responsive-table {
//> .ui-treetable-responsive {
//    overflow-x: auto;
//}
// }
p-table .vs-table-paginator {
    font-weight: normal;
    text-align: left;
}

.vs-table-wrapper {
    overflow: hidden;
    height: inherit; //-webkit-fill-available;
}

p-table p-sorticon {
    font-size: 10px;
}

// th
.vs-header-cell {
    display: flex;
    justify-content: space-between;
    .__title+.__filter {
        margin-left: 4px;
    }
    .__title {
        flex: 1 10;
    }
    .ui-multiselect {
        background-color: transparent;
        border: 0;
    }
    .ui-multiselect-label-container {
        display: none;
    }
    .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
        box-shadow: none;
        border: 0;
    }
    .ui-multiselect .ui-multiselect-trigger {
        position: static;
        line-height: inherit;
        width: 16px;
    }
    .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
        position: static;
        margin: 0;
    }
}

.vs-table-filter-container {
    .__filter-icon {
        padding-left: 8px;
    }
}

.vs-table-filter-overlay-wrapper {
    position: fixed;
    display: block !important;
    text-align: left;
    width: 220px;
    font-weight: 400;
    .ui-overlaypanel-content {
        padding: 0;
    }
}

.vs-table-filter-overlay {
    .__filter-area {
        padding: 5px 10px 10px;
        border-bottom: 1px solid #ddd;
        .__title {
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
    .__filter-area.area-1 {
        .__operator {
            display: block;
        }
        .__value {
            margin-top: 5px;
            width: 100%;
        }
        .__connective-block {
            padding: 5px 0;
            p-radioButton {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    .__filter-area.area-2 {
        .__search-box {
            width: 100%;
        }
        .__col-values-actions {
            padding: 5px 0;
            text-align: right;
            .__action:hover {
                text-decoration: underline;
            }
            .__action+.__action {
                margin-left: 5px;
            }
        }
        .__col-value-items {
            max-height: 150px;
            border: 1px solid #ddd;
            padding: 3px 0;
            overflow-y: auto;
            p-checkbox {
                display: flex;
                padding: 3px 8px;
                >label {
                    flex: 1;
                }
                &:hover {
                    background-color: #eeeeee;
                }
            }
        }
    }
    .__action-area {
        text-align: right;
        padding: 10px;
        .vs-btn+.vs-btn {
            margin-left: 5px;
        }
    }
}

// table
.vs-page-content-body-area {
    flex: 1 1;
    overflow: auto;
}

.vs-data-table-area,
.vs-data-table-area-inner,
.vs-data-table-area .__main-table-area {
    height: 100%;
}

.vs-data-table-area .__main-table-area {
    display: flex;
    flex-direction: column;
}

p-treetable.vs-table {
    display: block;
    &,
    .ui-treetable,
    .ui-treetable-scrollable-view {
        height: 100%;
    }
    .ui-treetable {
        display: flex;
        flex-direction: column;
    }
    .ui-treetable-scrollable-wrapper {
        flex: 1 1;
        overflow: hidden;
    }
    .ui-treetable-scrollable-view {
        display: flex;
        flex-direction: column;
    }
    .ui-treetable-scrollable-body {
        flex: 1 10;
    }
    .ui-treetable-wrapper {
        overflow-x: auto;
    }
    &.inherit-max-height {
        &,
        .ui-treetable,
        .ui-treetable-wrapper,
        .ui-treetable-scrollable-wrapper,
        .ui-treetable-scrollable-view {
            max-height: inherit;
        }
    }
    .ui-widget-header {
        border: 0;
    }
    thead {
        background: #1ca7ff;
    }
    .ui-widget-overlay {
        background: #fff;
    }
    p-paginator .ui-paginator {
        text-align: right;
        padding: 5px 5px 3px 10px;
        border-top: 1px solid #e6eaee;
        .ui-paginator-first,
        .ui-paginator-prev,
        .ui-paginator-next,
        .ui-paginator-last {
            margin-right: 0;
            margin-left: 0;
            min-width: 28px;
            min-height: 28px;
        }
        .ui-paginator-page {
            min-width: 28px;
            min-height: 28px;
            padding-left: 6px;
            padding-right: 6px;
        }
    }
    td {
        input[type="text"],
        input[type="search"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="tel"],
        input[type="number"],
        input[type="date"] {
            padding-left: 6px;
            padding-right: 6px;
        }
        .ui-dropdown {
            min-width: 0;
        }
    }
}

// table
p-treetable.vs-table {
    display: block;
    &,
    .ui-treetable {
        width: 100%;
    }
    .ui-treetable-wrapper {
        overflow-x: auto;
    }
    &.inherit-max-height {
        &,
        .ui-treetable,
        .ui-treetable-wrapper,
        .ui-treetable-scrollable-wrapper,
        .ui-treetable-scrollable-view {
            max-height: inherit;
        }
    }
    .ui-widget-header {
        border: 0;
    }
    thead {
        background: #1ca7ff;
    }
    .ui-widget-overlay {
        background: #fff;
    }
    p-paginator .ui-paginator {
        text-align: right;
        padding: 10px 15px;
        .ui-paginator-first,
        .ui-paginator-prev,
        .ui-paginator-next,
        .ui-paginator-last {
            margin-right: 0;
            margin-left: 0;
            min-width: 28px;
            min-height: 28px;
        }
        .ui-paginator-page {
            min-width: 28px;
            min-height: 28px;
            padding-left: 6px;
            padding-right: 6px;
        }
    }
    td {
        input[type="text"],
        input[type="search"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="tel"],
        input[type="number"],
        input[type="date"] {
            padding-left: 6px;
            padding-right: 6px;
        }
        .ui-dropdown {
            min-width: 0;
        }
    }
}

// hot fix
body .ui-treetable .ui-treetable-tbody>tr.highlight-border-bottom>td {
    border-bottom: 1px solid #bbb;
}

body .ui-treetable .ui-treetable-tbody>tr.highlight>td {
    border: 1px solid #bbb;
}

body .ui-treetable .ui-treetable-tbody>tr.highlight {
    background-color: #f2f2f2;
}

body .ui-treetable .ui-treetable-tbody>tr:nth-child(even).highlight {
    background-color: #e9e9e9;
}

body .ui-treetable span.ui-sortable-column.ui-state-highlight {
    background-color: transparent;
    outline: none;
}

body .ui-treetable span.ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    // color: $vs-base-color;
    color: white;
}

p-table.align-cell-top {
    .ui-treetable .ui-treetable-tbody>tr>td {
        vertical-align: top;
    }
}

p-table.no-border>.ui-treetable {
    tr>td,
    tr>th {
        border: 0;
    }
}

.vs-table-footer {
    background: #fff;
    width: 100%;
    align-items: center;
    border: 1px solid #dddddd;
    border-top: none;
    padding: 15px;
}

body .ui-treetable .ui-treetable-tbody>tr>td.vs-td-sunday {
    background-color: #d9d9d9;
}