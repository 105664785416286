/* You can add global styles to this file, and also import other style files */
@import url(primeicons/primeicons.css);
body {
  font-size: 12px; }

body.landing-body {
  background-color: #fff; }

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin-top: 0;
  margin-bottom: 20px; }

input,
textarea,
button,
select {
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  outline: none;
  text-decoration: none; }

body .ui-widget,
body .ui-widget .ui-widget {
  font-family: inherit; }

/**
@Padding
*/
.m-0 {
  margin: 0px !important; }

.m-5 {
  margin: 5px !important; }

.m-10 {
  margin: 10px !important; }

.m-15 {
  margin: 15px !important; }

.m-20 {
  margin: 20px !important; }

.m-25 {
  margin: 25px !important; }

.m-30 {
  margin: 30px !important; }

.m-35 {
  margin: 35px !important; }

.m-40 {
  margin: 40px !important; }

.m-50 {
  margin: 50px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.ml-0 {
  margin-left: 0px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-50 {
  margin-left: 50px !important; }

.pd-0 {
  padding: 0px !important; }

.pd-5 {
  padding: 5px !important; }

.pd-10 {
  padding: 10px !important; }

.pd-15 {
  padding: 15px !important; }

.pd-20 {
  padding: 20px !important; }

.pd-25 {
  padding: 25px !important; }

.pd-30 {
  padding: 30px !important; }

.pd-35 {
  padding: 35px !important; }

.pd-40 {
  padding: 40px !important; }

.pd-50 {
  padding: 50px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-50 {
  padding-left: 50px !important; }

/**
@border-radius
*/
.br-3 {
  border-radius: 3px !important; }

.br-5 {
  border-radius: 5px !important; }

.br-6 {
  border-radius: 6px !important; }

.br-8 {
  border-radius: 8px !important; }

.br-10 {
  border-radius: 10px !important; }

.br-15 {
  border-radius: 15px !important; }

.br-20 {
  border-radius: 20px !important; }

.br-25 {
  border-radius: 25px !important; }

.border-1 {
  border: 1px solid var(--clr-common-gray3); }

.box-shadow-0 {
  box-shadow: unset !important; }

.flex-1 {
  flex: 1; }

.hidden, [hidden] {
  display: none !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media only screen and (min-width: 1025px) {
  .hidden-on-desktop {
    display: none; }
  .hidden-on-desktop-i {
    display: none !important; } }

@media only screen and (max-width: 1024px) {
  .hidden-on-small-device {
    display: none; }
  .hidden-on-small-device-i {
    display: none !important; } }

.block-el {
  display: block; }

.inline-block-el {
  display: inline-block; }

.flex-el {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .flex-el.align-content-top {
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start; }
  .flex-el.align-content-middle {
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }
  .flex-el.align-content-bottom {
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end; }
  .flex-el.align-content-center {
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
  .flex-el.align-content-left {
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .flex-el.align-content-right {
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .flex-el.space-between-content {
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .flex-el.space-around-content {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around; }
  .flex-el.wrap-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .flex-el.x-direction {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-el.x-reverse-direction {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .flex-el.y-direction {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-el.y-reverse-direction {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.cursor-pointer {
  cursor: pointer; }

.cursor-not-allowed {
  cursor: not-allowed; }

.overflow-hidden {
  overflow: hidden; }

.overflow-auto {
  overflow: auto; }

.overflow-x-auto {
  overflow-x: auto; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-hidden {
  overflow-y: hidden; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-underline,
.text-hover-underline.activated,
.text-hover-underline:hover {
  text-decoration: underline; }

.text-line-through {
  text-decoration: line-through; }

.fs-inherit {
  font-style: inherit; }

.fs-italic {
  font-style: italic; }

.fs-normal {
  font-style: normal; }

.vertical-align-top {
  vertical-align: top; }

.vertical-align-middle {
  vertical-align: middle; }

.vertical-align-bottom {
  vertical-align: bottom; }

.align-left,
.ui-text-left {
  text-align: left; }

.align-center,
.ui-text-center {
  text-align: center; }

.align-right,
.ui-text-right {
  text-align: right; }

@media only screen and (max-width: 640px) {
  .align-sm-left,
  .ui-text-sm-left {
    text-align: left; }
  .align-sm-center,
  .ui-text-sm-center {
    text-align: center; }
  .align-sm-right,
  .ui-text-sm-right {
    text-align: right; } }

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .align-md-left,
  .ui-text-md-left {
    text-align: left; }
  .align-md-center,
  .ui-text-md-center {
    text-align: center; }
  .align-md-right,
  .ui-text-md-right {
    text-align: right; } }

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .align-lg-left,
  .ui-text-lg-left {
    text-align: left; }
  .align-lg-center,
  .ui-text-lg-center {
    text-align: center; }
  .align-lg-right,
  .ui-text-lg-right {
    text-align: right; } }

@media only screen and (min-width: 1441px) {
  .align-xl-left,
  .ui-text-xl-left {
    text-align: left; }
  .align-xl-center,
  .ui-text-xl-center {
    text-align: center; }
  .align-xl-right,
  .ui-text-xl-right {
    text-align: right; } }

.txtRight {
  text-align: right; }

.fw-normal, .font-weight-normal {
  font-weight: normal; }

.prevent-line-break {
  white-space: nowrap; }

.break-word {
  word-wrap: break-word; }

.fw-inherit {
  font-weight: inherit; }

.fw-inherit-i {
  font-weight: inherit !important; }

.fw-light {
  font-weight: 300; }

.fw-light-i {
  font-weight: 300 !important; }

.fw-regular {
  font-weight: 400; }

.fw-regular-i {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500; }

.fw-medium-i {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600; }

.fw-semibold-i {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700; }

.fw-bold-i {
  font-weight: 700 !important; }

.ui-g-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px; }

[class^="ui-g-"], [class*=" ui-g-"],
[class^="ui-md-"], [class*=" ui-md-"],
[class^="ui-lg-"], [class*=" ui-lg-"],
[class^="ui-xl-"], [class*=" ui-xl-"] {
  padding: 0 7.5px; }
  [class^="ui-g-"].ui-g-nopad, [class*=" ui-g-"].ui-g-nopad,
  [class^="ui-md-"].ui-g-nopad, [class*=" ui-md-"].ui-g-nopad,
  [class^="ui-lg-"].ui-g-nopad, [class*=" ui-lg-"].ui-g-nopad,
  [class^="ui-xl-"].ui-g-nopad, [class*=" ui-xl-"].ui-g-nopad {
    padding: 0; }

.ui-g.row {
  margin-left: -7.5px;
  margin-right: -7.5px; }
  .ui-g.row.large-gutter {
    margin-left: -15px;
    margin-right: -15px; }

.ui-g.large-gutter [class^="ui-g-"], .ui-g.large-gutter [class*=" ui-g-"],
.ui-g.large-gutter [class^="ui-md-"], .ui-g.large-gutter [class*=" ui-md-"],
.ui-g.large-gutter [class^="ui-lg-"], .ui-g.large-gutter [class*=" ui-lg-"],
.ui-g.large-gutter [class^="ui-xl-"], .ui-g.large-gutter [class*=" ui-xl-"] {
  padding-left: 15px;
  padding-right: 15px; }
  .ui-g.large-gutter [class^="ui-g-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-g-"].ui-g-nopad,
  .ui-g.large-gutter [class^="ui-md-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-md-"].ui-g-nopad,
  .ui-g.large-gutter [class^="ui-lg-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-lg-"].ui-g-nopad,
  .ui-g.large-gutter [class^="ui-xl-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-xl-"].ui-g-nopad {
    padding: 0; }

.clearfix::after {
  content: "";
  clear: both;
  display: table; }

@media (max-width: 1024px) {
  .txtRight {
    text-align: left; } }

.m-5, .my-5, .mt-5 {
  margin-top: 5px; }

.m-5, .my-5, .mb-5 {
  margin-bottom: 5px; }

.m-5, .mx-5, .ml-5 {
  margin-left: 5px; }

.m-5, .mx-5, .mr-5 {
  margin-right: 5px; }

.m-10, .my-10, .mt-10 {
  margin-top: 10px; }

.m-10, .my-10, .mb-10 {
  margin-bottom: 10px; }

.m-10, .mx-10, .ml-10 {
  margin-left: 10px; }

.m-10, .mx-10, .mr-10 {
  margin-right: 10px; }

.m-15, .my-15, .mt-15 {
  margin-top: 15px; }

.m-15, .my-15, .mb-15 {
  margin-bottom: 15px; }

.m-15, .mx-15, .ml-15 {
  margin-left: 15px; }

.m-15, .mx-15, .mr-15 {
  margin-right: 15px; }

.m-20, .my-20, .mt-20 {
  margin-top: 20px; }

.m-20, .my-20, .mb-20 {
  margin-bottom: 20px; }

.m-20, .mx-20, .ml-20 {
  margin-left: 20px; }

.m-20, .mx-20, .mr-20 {
  margin-right: 20px; }

.m-25, .my-25, .mt-25 {
  margin-top: 25px; }

.m-25, .my-25, .mb-25 {
  margin-bottom: 25px; }

.m-25, .mx-25, .ml-25 {
  margin-left: 25px; }

.m-25, .mx-25, .mr-25 {
  margin-right: 25px; }

.m-30, .my-30, .mt-30 {
  margin-top: 30px; }

.m-30, .my-30, .mb-30 {
  margin-bottom: 30px; }

.m-30, .mx-30, .ml-30 {
  margin-left: 30px; }

.m-30, .mx-30, .mr-30 {
  margin-right: 30px; }

.m-n-5, .my-n-5, .mt-n-5 {
  margin-top: -5px; }

.m-n-5, .my-n-5, .mb-n-5 {
  margin-bottom: -5px; }

.m-n-5, .mx-n-5, .ml-n-5 {
  margin-left: -5px; }

.m-n-5, .mx-n-5, .mr-n-5 {
  margin-right: -5px; }

.m-n-10, .my-n-10, .mt-n-10 {
  margin-top: -10px; }

.m-n-10, .my-n-10, .mb-n-10 {
  margin-bottom: -10px; }

.m-n-10, .mx-n-10, .ml-n-10 {
  margin-left: -10px; }

.m-n-10, .mx-n-10, .mr-n-10 {
  margin-right: -10px; }

.m-n-15, .my-n-15, .mt-n-15 {
  margin-top: -15px; }

.m-n-15, .my-n-15, .mb-n-15 {
  margin-bottom: -15px; }

.m-n-15, .mx-n-15, .ml-n-15 {
  margin-left: -15px; }

.m-n-15, .mx-n-15, .mr-n-15 {
  margin-right: -15px; }

.m-n-20, .my-n-20, .mt-n-20 {
  margin-top: -20px; }

.m-n-20, .my-n-20, .mb-n-20 {
  margin-bottom: -20px; }

.m-n-20, .mx-n-20, .ml-n-20 {
  margin-left: -20px; }

.m-n-20, .mx-n-20, .mr-n-20 {
  margin-right: -20px; }

.m-n-25, .my-n-25, .mt-n-25 {
  margin-top: -25px; }

.m-n-25, .my-n-25, .mb-n-25 {
  margin-bottom: -25px; }

.m-n-25, .mx-n-25, .ml-n-25 {
  margin-left: -25px; }

.m-n-25, .mx-n-25, .mr-n-25 {
  margin-right: -25px; }

.m-n-30, .my-n-30, .mt-n-30 {
  margin-top: -30px; }

.m-n-30, .my-n-30, .mb-n-30 {
  margin-bottom: -30px; }

.m-n-30, .mx-n-30, .ml-n-30 {
  margin-left: -30px; }

.m-n-30, .mx-n-30, .mr-n-30 {
  margin-right: -30px; }

.p-5, .py-5, .pt-5 {
  padding-top: 5px; }

.p-5, .py-5, .pb-5 {
  padding-bottom: 5px; }

.p-5, .px-5, .pl-5 {
  padding-left: 5px; }

.p-5, .px-5, .pr-5 {
  padding-right: 5px; }

.p-10, .py-10, .pt-10 {
  padding-top: 10px; }

.p-10, .py-10, .pb-10 {
  padding-bottom: 10px; }

.p-10, .px-10, .pl-10 {
  padding-left: 10px; }

.p-10, .px-10, .pr-10 {
  padding-right: 10px; }

.p-15, .py-15, .pt-15 {
  padding-top: 15px; }

.p-15, .py-15, .pb-15 {
  padding-bottom: 15px; }

.p-15, .px-15, .pl-15 {
  padding-left: 15px; }

.p-15, .px-15, .pr-15 {
  padding-right: 15px; }

.p-20, .py-20, .pt-20 {
  padding-top: 20px; }

.p-20, .py-20, .pb-20 {
  padding-bottom: 20px; }

.p-20, .px-20, .pl-20 {
  padding-left: 20px; }

.p-20, .px-20, .pr-20 {
  padding-right: 20px; }

.p-25, .py-25, .pt-25 {
  padding-top: 25px; }

.p-25, .py-25, .pb-25 {
  padding-bottom: 25px; }

.p-25, .px-25, .pl-25 {
  padding-left: 25px; }

.p-25, .px-25, .pr-25 {
  padding-right: 25px; }

.p-30, .py-30, .pt-30 {
  padding-top: 30px; }

.p-30, .py-30, .pb-30 {
  padding-bottom: 30px; }

.p-30, .px-30, .pl-30 {
  padding-left: 30px; }

.p-30, .px-30, .pr-30 {
  padding-right: 30px; }

/* Customize tab */
p-tabPanel > .ui-tabview-panel {
  padding: 15px; }

p-tabPanel.no-padding > .ui-tabview-panel {
  padding: 0; }

.vs-form-container .__modal-tab-title {
  text-transform: uppercase;
  color: #0084ff;
  margin: 5px 0 15px; }

body .ui-menu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  margin-right: .6em; }

body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 10px;
  right: 15px;
  font-size: 14px; }

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item {
  padding: 0 10px;
  line-height: 28px; }

body .ui-inputtext {
  font-size: 12px;
  padding: 4px 8px;
  line-height: 20px; }

body .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 5px 10px; }

body .ui-dropdown .ui-dropdown-trigger {
  color: #cccccc; }

body .ui-dropdown .ui-dropdown-clear-icon {
  color: #868788; }

p-dropdown .ui-dropdown .ui-dropdown-label {
  padding-right: 24px; }

p-dropdown .ui-corner-all,
p-dropdown .ui-inputtext {
  border-radius: 0;
  box-shadow: none; }

p-dropdown .ui-placeholder {
  color: #888; }

p-multiselect .ui-corner-all,
p-multiselect .ui-inputtext {
  border-radius: 0; }

p-multiselect .ui-placeholder {
  color: #888; }

.vs-custom-dropdown-wrapper {
  position: relative;
  z-index: 10; }
  .vs-custom-dropdown-wrapper.inline {
    display: inline-block; }

.vs-custom-dropdown {
  position: absolute;
  width: 200px;
  z-index: 10;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3); }
  .vs-custom-dropdown.large-size {
    width: 250px; }
  .vs-custom-dropdown.top-left {
    bottom: 100%;
    right: 0; }
  .vs-custom-dropdown.top-right {
    bottom: 100%;
    left: 0; }
  .vs-custom-dropdown.bottom-left {
    top: 100%;
    right: 0; }
  .vs-custom-dropdown.bottom-right {
    top: 100%;
    left: 0; }
  .vs-custom-dropdown.top-left, .vs-custom-dropdown.top-right {
    transform: translateY(-5px); }
  .vs-custom-dropdown.bottom-left, .vs-custom-dropdown.bottom-right {
    transform: translateY(5px); }
  .vs-custom-dropdown.style-1 {
    padding: 10px; }
    .vs-custom-dropdown.style-1 .__action {
      padding: 5px 10px;
      color: #212529;
      display: block;
      -webkit-transition: background-color 0.3s ease-out;
      -o-transition: background-color 0.3s ease-out;
      transition: background-color 0.3s ease-out; }
      .vs-custom-dropdown.style-1 .__action:hover {
        background-color: rgba(46, 159, 224, 0.7); }

body .ui-multiselect {
  display: block;
  border-radius: 0; }

body .ui-multiselect-panel,
body .ui-multiselect .ui-multiselect-panel {
  min-width: 200px; }

body .ui-multiselect-panel .ui-multiselect-header {
  padding: 5px 20px 5px 10px; }

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  font-size: 14px;
  top: 5px;
  left: 5px; }

.ui-multiselect-panel .ui-multiselect-item .ui-chkbox {
  margin-right: 5px; }

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  padding: 6px 10px; }

body .ui-multiselect-label .vs-multi-select-tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 20px 2px 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #0095da;
  color: #ffffff; }

body .ui-multiselect-label .vs-multi-select-tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 6px;
  margin: 2px 3px 2px 0;
  background-color: #0095da;
  color: #ffffff; }

body > .ui-multiselect-panel {
  position: fixed;
  display: block !important; }

body .ui-multiselect-label .vs-multi-select-placeholder {
  padding: 2px 6px;
  margin-top: 2px;
  margin-bottom: 2px; }

mwl-text-input-autocomplete-menu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 200px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

mwl-text-input-autocomplete-menu .dropdown-menu > .active > a,
mwl-text-input-autocomplete-menu .dropdown-menu > .active > a:focus,
mwl-text-input-autocomplete-menu .dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0095da;
  outline: 0; }

mwl-text-input-autocomplete-menu .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; }

.vs-custom-overlay-panel-wrapper .ui-overlaypanel-content {
  padding: 10px; }

.vs-custom-overlay-panel .__action {
  padding: 5px 10px;
  color: #212529;
  display: block;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }
  .vs-custom-overlay-panel .__action:hover {
    background-color: rgba(46, 159, 224, 0.7); }

.vs-dropdown-with-table ul.ui-dropdown-items,
.vs-dropdown-with-table .ui-th,
.vs-dropdown-with-table .ui-td {
  border: 1px solid #ddd; }

.vs-dropdown-with-table ul.ui-dropdown-items {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }

.vs-dropdown-with-table .ui-th,
.vs-dropdown-with-table .ui-td {
  display: table-cell;
  padding: 6px; }

.vs-dropdown-with-table li.ui-dropdown-item-group,
.vs-dropdown-with-table li.ui-dropdown-item {
  display: table-row; }

.vs-dropdown-with-table li.ui-dropdown-item-group {
  background: #eee; }

.vs-dropdown-with-table p-dropdownitem {
  display: table-row-group; }

.vs-dropdown-with-table .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 10px; }

.vs-dropdown-with-table .ui-dropdown-items-wrapper {
  padding: 5px 10px 10px; }

input[type="text"],
input[type="search"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea {
  padding-left: 8px;
  padding-right: 8px;
  height: 30px;
  outline: none;
  font-size: 12px;
  border: 1px solid #dfe7ef;
  border-radius: 0; }
  input[type="text"][readonly],
  input[type="search"][readonly],
  input[type="email"][readonly],
  input[type="url"][readonly],
  input[type="password"][readonly],
  input[type="tel"][readonly],
  input[type="number"][readonly],
  input[type="date"][readonly],
  textarea[readonly] {
    background: #eeeeee; }
  input[type="text"].full-width,
  input[type="search"].full-width,
  input[type="email"].full-width,
  input[type="url"].full-width,
  input[type="password"].full-width,
  input[type="tel"].full-width,
  input[type="number"].full-width,
  input[type="date"].full-width,
  textarea.full-width {
    width: 100%; }

input[type="number"] {
  padding-right: 0; }

textarea {
  padding: 6px 12px;
  height: auto;
  resize: vertical; }

body .ui-radiobutton-label:hover,
body .ui-chkbox-label:hover {
  cursor: pointer; }

.vs-form-control .__form-control-label {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .vs-form-control .__form-control-label.no-bgc {
    background-color: transparent; }

.vs-form-control .__form-control-inputs {
  display: flex;
  padding: 0; }
  .vs-form-control .__form-control-inputs > div {
    margin-right: 10px;
    flex: 1 1; }
  .vs-form-control .__form-control-inputs > div:last-of-type {
    margin-right: 0; }
  .vs-form-control .__form-control-inputs input[type="text"],
  .vs-form-control .__form-control-inputs input[type="search"],
  .vs-form-control .__form-control-inputs input[type="email"],
  .vs-form-control .__form-control-inputs input[type="url"],
  .vs-form-control .__form-control-inputs input[type="password"],
  .vs-form-control .__form-control-inputs input[type="number"],
  .vs-form-control .__form-control-inputs input[type="tel"],
  .vs-form-control .__form-control-inputs input[type="date"],
  .vs-form-control .__form-control-inputs textarea {
    width: 100%; }
  .vs-form-control .__form-control-inputs .ui-dropdown {
    min-width: 0; }
  .vs-form-control .__form-control-inputs .__radio-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around; }

.vs-form-container .vs-form-control {
  margin-bottom: 5px; }
  .vs-form-container .vs-form-control .__form-control-label {
    margin-bottom: 5px; }
  .vs-form-container .vs-form-control .__form-control-inputs {
    margin-bottom: 5px;
    align-items: center; }
    .vs-form-container .vs-form-control .__form-control-inputs .__input-wrapper.required {
      position: relative; }
      .vs-form-container .vs-form-control .__form-control-inputs .__input-wrapper.required:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        border: 6px solid #ff882e;
        width: 0;
        height: 0;
        border-bottom-color: transparent;
        border-left-color: transparent; }

.vs-form-container.default-control-label-width .vs-form-control {
  display: flex; }
  @media (min-width: 40.063em) {
    .vs-form-container.default-control-label-width .vs-form-control {
      flex-wrap: nowrap; }
      .vs-form-container.default-control-label-width .vs-form-control .__form-control-label {
        min-width: 160px;
        width: 160px; }
      .vs-form-container.default-control-label-width .vs-form-control .__form-control-inputs {
        flex-grow: 1; } }

.vs-custom-checkbox {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer; }
  .vs-custom-checkbox input {
    display: none; }
  .vs-custom-checkbox .__mark {
    display: block;
    border-radius: 2px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.3s, background-color 0.3s, box-shadow 0.3s; }
    .vs-custom-checkbox .__mark:before {
      content: "\e909";
      font-family: 'primeicons';
      font-size: 14px;
      line-height: 14px;
      display: block;
      opacity: 0; }
  .vs-custom-checkbox input:disabled + .__mark {
    cursor: default;
    background-color: #eeeeee;
    color: #777777; }
  .vs-custom-checkbox input:checked + .__mark {
    border: 1px solid #0095da;
    background-color: #0095da;
    color: #fff; }
    .vs-custom-checkbox input:checked + .__mark:before {
      opacity: 1; }

body .ui-chkbox .ui-chkbox-box {
  width: 16px;
  height: 16px; }

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  font-size: 14px;
  line-height: 14px; }

body .ui-radiobutton .ui-radiobutton-box {
  width: 16px;
  height: 16px; }

.vs-input-wrapper.required {
  position: relative; }
  .vs-input-wrapper.required:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    border: 6px solid #ff882e;
    width: 0;
    height: 0;
    border-bottom-color: transparent;
    border-left-color: transparent; }

.vs-input.input-with-icon {
  position: relative; }
  .vs-input.input-with-icon .__input {
    padding-right: 30px; }
  .vs-input.input-with-icon .__icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center; }

.ui-radiobutton-icon {
  margin: 0; }

body .ui-radiobutton {
  vertical-align: top; }

body .ui-radiobutton .ui-radiobutton-box {
  display: flex;
  align-items: center;
  justify-content: center; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
  padding: 0 8px;
  border-radius: 0;
  flex: 1 1;
  min-width: 250px;
  min-height: 30px; }

body .ui-autocomplete .ui-autocomplete-dropdown {
  width: 24px;
  height: 24px;
  border-radius: 0; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 20px 2px 6px;
  margin-top: 2px;
  margin-bottom: 2px; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  padding: 0;
  height: 18px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 28px; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-size: 12px;
  height: 18px;
  border: 0; }

p-autocomplete .ui-autocomplete {
  display: flex; }

p-autocomplete.ui-inputwrapper-filled .ui-autocomplete-input-token input {
  width: 70px; }

button:disabled {
  cursor: not-allowed; }

.btn {
  outline: none;
  cursor: pointer;
  line-height: 30px;
  font-size: inherit;
  display: block;
  background: #0095da;
  color: white;
  border-radius: 2px;
  line-height: 30px;
  border: 0;
  padding: 0 15px; }
  .btn i {
    padding-right: 5px; }

.vs-btn {
  outline: none;
  cursor: pointer;
  line-height: 30px;
  font-size: inherit;
  font-weight: 600;
  padding: 0 15px;
  border: 0;
  border-radius: 5px;
  display: inline-block;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }
  .vs-btn .__icon {
    font-size: 12px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .vs-btn .__label {
    padding-left: 5px; }
  .vs-btn.icon-only {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 24px; }
    .vs-btn.icon-only .__icon {
      display: block;
      font-size: 16px; }
  .vs-btn.easing {
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .vs-btn.no-border {
    border: 0; }
  .vs-btn.default-border {
    border: 1px solid #ddd; }

td > .vs-table-row-actions {
  margin-top: -5px;
  margin-bottom: -5px; }

.vs-table-row-actions .vs-btn {
  margin: 1px; }

.vs-btn.vs-top-table-btn,
.vs-btn.vs-modal-btn {
  position: relative;
  border-radius: 5px; }
  .vs-btn.vs-top-table-btn.no-icon,
  .vs-btn.vs-modal-btn.no-icon {
    padding-left: 10px; }
  .vs-btn.vs-top-table-btn.middle-icon .__icon,
  .vs-btn.vs-modal-btn.middle-icon .__icon {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.vs-btn.vs-top-table-btn {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0px;
  -webkit-transition: border-color 0.3s ease-out;
  -o-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out; }
  .vs-btn.vs-top-table-btn.trigger-dropdown {
    padding-right: 20px; }
  .vs-btn.vs-top-table-btn .__icon {
    line-height: 28px; }
  .vs-btn.vs-top-table-btn .__dropdown-hint {
    position: absolute;
    top: 0;
    height: 100%;
    color: #888;
    right: 0;
    width: 20px;
    padding-top: 6px; }
  .vs-btn.vs-top-table-btn.right-icon {
    padding-left: 10px;
    padding-right: 26px; }
    .vs-btn.vs-top-table-btn.right-icon .__icon {
      right: 0;
      left: auto; }
  .vs-btn.vs-top-table-btn:hover {
    background-color: #ddd; }

.vs-btn.vs-modal-btn {
  border: 0;
  color: #fff;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.vs-modal-more-action-btn,
.vs-advance-actions-btn {
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 2px;
  width: 24px; }

.vs-btn-null {
  background: #fff;
  color: #9fa7bd !important; }
  .vs-btn-null:hover {
    background-color: #818ba8; }

.vs-btn-blue {
  background: #0084ff !important;
  color: #FFF !important; }
  .vs-btn-blue:hover {
    background-color: #006acc; }

.vs-btn-icon-blue {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-blue .__icon {
    color: #0084ff;
    font-size: 16px; }
  .vs-btn-icon-blue:hover {
    background-color: #ddd; }
    .vs-btn-icon-blue:hover .__icon {
      color: #006acc; }

.vs-btn-action-blue:not(.vs-modal-btn) .__icon {
  color: #0084ff; }

.vs-btn-action-blue:not(.vs-modal-btn):hover .__icon {
  color: #006acc; }

.vs-btn-action-blue.vs-modal-btn {
  background-color: #0084ff; }
  .vs-btn-action-blue.vs-modal-btn:hover {
    background-color: #006acc; }

.vs-btn-green {
  background: #14b545 !important;
  color: #FFF !important; }
  .vs-btn-green:hover {
    background-color: #0f8733; }

.vs-btn-icon-green {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-green .__icon {
    color: #14b545;
    font-size: 16px; }
  .vs-btn-icon-green:hover {
    background-color: #ddd; }
    .vs-btn-icon-green:hover .__icon {
      color: #0f8733; }

.vs-btn-action-green:not(.vs-modal-btn) .__icon {
  color: #14b545; }

.vs-btn-action-green:not(.vs-modal-btn):hover .__icon {
  color: #0f8733; }

.vs-btn-action-green.vs-modal-btn {
  background-color: #14b545; }
  .vs-btn-action-green.vs-modal-btn:hover {
    background-color: #0f8733; }

.vs-btn-orange {
  background: #ff8800 !important;
  color: #FFF !important; }
  .vs-btn-orange:hover {
    background-color: #cc6d00; }

.vs-btn-icon-orange {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-orange .__icon {
    color: #ff8800;
    font-size: 16px; }
  .vs-btn-icon-orange:hover {
    background-color: #ddd; }
    .vs-btn-icon-orange:hover .__icon {
      color: #cc6d00; }

.vs-btn-action-orange:not(.vs-modal-btn) .__icon {
  color: #ff8800; }

.vs-btn-action-orange:not(.vs-modal-btn):hover .__icon {
  color: #cc6d00; }

.vs-btn-action-orange.vs-modal-btn {
  background-color: #ff8800; }
  .vs-btn-action-orange.vs-modal-btn:hover {
    background-color: #cc6d00; }

.vs-btn-red {
  background: #f44c4c !important;
  color: #FFF !important; }
  .vs-btn-red:hover {
    background-color: #f11c1c; }

.vs-btn-icon-red {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-red .__icon {
    color: #f44c4c;
    font-size: 16px; }
  .vs-btn-icon-red:hover {
    background-color: #ddd; }
    .vs-btn-icon-red:hover .__icon {
      color: #f11c1c; }

.vs-btn-action-red:not(.vs-modal-btn) .__icon {
  color: #f44c4c; }

.vs-btn-action-red:not(.vs-modal-btn):hover .__icon {
  color: #f11c1c; }

.vs-btn-action-red.vs-modal-btn {
  background-color: #f44c4c; }
  .vs-btn-action-red.vs-modal-btn:hover {
    background-color: #f11c1c; }

.vs-btn-gray {
  background: #999 !important;
  color: #FFF !important; }
  .vs-btn-gray:hover {
    background-color: #807f7f; }

.vs-btn-icon-gray {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-gray .__icon {
    color: #999;
    font-size: 16px; }
  .vs-btn-icon-gray:hover {
    background-color: #ddd; }
    .vs-btn-icon-gray:hover .__icon {
      color: #807f7f; }

.vs-btn-action-gray:not(.vs-modal-btn) .__icon {
  color: #999; }

.vs-btn-action-gray:not(.vs-modal-btn):hover .__icon {
  color: #807f7f; }

.vs-btn-action-gray.vs-modal-btn {
  background-color: #999; }
  .vs-btn-action-gray.vs-modal-btn:hover {
    background-color: #807f7f; }

.vs-btn-yellow {
  background: #fda738 !important;
  color: #FFF !important; }
  .vs-btn-yellow:hover {
    background-color: #fc9106; }

.vs-btn-icon-yellow {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-yellow .__icon {
    color: #fda738;
    font-size: 16px; }
  .vs-btn-icon-yellow:hover {
    background-color: #ddd; }
    .vs-btn-icon-yellow:hover .__icon {
      color: #fc9106; }

.vs-btn-action-yellow:not(.vs-modal-btn) .__icon {
  color: #fda738; }

.vs-btn-action-yellow:not(.vs-modal-btn):hover .__icon {
  color: #fc9106; }

.vs-btn-action-yellow.vs-modal-btn {
  background-color: #fda738; }
  .vs-btn-action-yellow.vs-modal-btn:hover {
    background-color: #fc9106; }

.vs-btn-violet {
  background: #7f99f1 !important;
  color: #FFF !important; }
  .vs-btn-violet:hover {
    background-color: #5174ec; }

.vs-btn-icon-violet {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-violet .__icon {
    color: #7f99f1;
    font-size: 16px; }
  .vs-btn-icon-violet:hover {
    background-color: #ddd; }
    .vs-btn-icon-violet:hover .__icon {
      color: #5174ec; }

.vs-btn-action-violet:not(.vs-modal-btn) .__icon {
  color: #7f99f1; }

.vs-btn-action-violet:not(.vs-modal-btn):hover .__icon {
  color: #5174ec; }

.vs-btn-action-violet.vs-modal-btn {
  background-color: #7f99f1; }
  .vs-btn-action-violet.vs-modal-btn:hover {
    background-color: #5174ec; }

.vs-btn-theme-blue {
  background: #0095da !important;
  color: #FFF !important; }
  .vs-btn-theme-blue:hover {
    background-color: #0072a7; }

.vs-btn-icon-theme-blue {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-theme-blue .__icon {
    color: #0095da;
    font-size: 16px; }
  .vs-btn-icon-theme-blue:hover {
    background-color: #ddd; }
    .vs-btn-icon-theme-blue:hover .__icon {
      color: #0072a7; }

.vs-btn-action-theme-blue:not(.vs-modal-btn) .__icon {
  color: #0095da; }

.vs-btn-action-theme-blue:not(.vs-modal-btn):hover .__icon {
  color: #0072a7; }

.vs-btn-action-theme-blue.vs-modal-btn {
  background-color: #0095da; }
  .vs-btn-action-theme-blue.vs-modal-btn:hover {
    background-color: #0072a7; }

.vs-btn-base {
  background: #1ca7ff !important;
  color: #FFF !important; }
  .vs-btn-base:hover {
    background-color: #008ee8; }

.vs-btn-icon-base {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-base .__icon {
    color: #1ca7ff;
    font-size: 16px; }
  .vs-btn-icon-base:hover {
    background-color: #ddd; }
    .vs-btn-icon-base:hover .__icon {
      color: #008ee8; }

.vs-btn-action-base:not(.vs-modal-btn) .__icon {
  color: #1ca7ff; }

.vs-btn-action-base:not(.vs-modal-btn):hover .__icon {
  color: #008ee8; }

.vs-btn-action-base.vs-modal-btn {
  background-color: #1ca7ff; }
  .vs-btn-action-base.vs-modal-btn:hover {
    background-color: #008ee8; }

.vs-btn-info {
  background: #2e9fe0 !important;
  color: #FFF !important; }
  .vs-btn-info:hover {
    background-color: #1c83bf; }

.vs-btn-icon-info {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-info .__icon {
    color: #2e9fe0;
    font-size: 16px; }
  .vs-btn-icon-info:hover {
    background-color: #ddd; }
    .vs-btn-icon-info:hover .__icon {
      color: #1c83bf; }

.vs-btn-action-info:not(.vs-modal-btn) .__icon {
  color: #2e9fe0; }

.vs-btn-action-info:not(.vs-modal-btn):hover .__icon {
  color: #1c83bf; }

.vs-btn-action-info.vs-modal-btn {
  background-color: #2e9fe0; }
  .vs-btn-action-info.vs-modal-btn:hover {
    background-color: #1c83bf; }

.vs-btn-danger {
  background: #b82020 !important;
  color: #FFF !important; }
  .vs-btn-danger:hover {
    background-color: #8d1818; }

.vs-btn-icon-danger {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-danger .__icon {
    color: #b82020;
    font-size: 16px; }
  .vs-btn-icon-danger:hover {
    background-color: #ddd; }
    .vs-btn-icon-danger:hover .__icon {
      color: #8d1818; }

.vs-btn-action-danger:not(.vs-modal-btn) .__icon {
  color: #b82020; }

.vs-btn-action-danger:not(.vs-modal-btn):hover .__icon {
  color: #8d1818; }

.vs-btn-action-danger.vs-modal-btn {
  background-color: #b82020; }
  .vs-btn-action-danger.vs-modal-btn:hover {
    background-color: #8d1818; }

.vs-btn-edit {
  background: #0084ff !important;
  color: #FFF !important; }
  .vs-btn-edit:hover {
    background-color: #006acc; }

.vs-btn-icon-edit {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-edit .__icon {
    color: #0084ff;
    font-size: 16px; }
  .vs-btn-icon-edit:hover {
    background-color: #ddd; }
    .vs-btn-icon-edit:hover .__icon {
      color: #006acc; }

.vs-btn-action-edit:not(.vs-modal-btn) .__icon {
  color: #0084ff; }

.vs-btn-action-edit:not(.vs-modal-btn):hover .__icon {
  color: #006acc; }

.vs-btn-action-edit.vs-modal-btn {
  background-color: #0084ff; }
  .vs-btn-action-edit.vs-modal-btn:hover {
    background-color: #006acc; }

.vs-btn-remove {
  background: #f44c4c !important;
  color: #FFF !important; }
  .vs-btn-remove:hover {
    background-color: #f11c1c; }

.vs-btn-icon-remove {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-remove .__icon {
    color: #f44c4c;
    font-size: 16px; }
  .vs-btn-icon-remove:hover {
    background-color: #ddd; }
    .vs-btn-icon-remove:hover .__icon {
      color: #f11c1c; }

.vs-btn-action-remove:not(.vs-modal-btn) .__icon {
  color: #f44c4c; }

.vs-btn-action-remove:not(.vs-modal-btn):hover .__icon {
  color: #f11c1c; }

.vs-btn-action-remove.vs-modal-btn {
  background-color: #f44c4c; }
  .vs-btn-action-remove.vs-modal-btn:hover {
    background-color: #f11c1c; }

.vs-btn-restore {
  background: #fda738 !important;
  color: #FFF !important; }
  .vs-btn-restore:hover {
    background-color: #fc9106; }

.vs-btn-icon-restore {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-restore .__icon {
    color: #fda738;
    font-size: 16px; }
  .vs-btn-icon-restore:hover {
    background-color: #ddd; }
    .vs-btn-icon-restore:hover .__icon {
      color: #fc9106; }

.vs-btn-action-restore:not(.vs-modal-btn) .__icon {
  color: #fda738; }

.vs-btn-action-restore:not(.vs-modal-btn):hover .__icon {
  color: #fc9106; }

.vs-btn-action-restore.vs-modal-btn {
  background-color: #fda738; }
  .vs-btn-action-restore.vs-modal-btn:hover {
    background-color: #fc9106; }

.vs-btn-back {
  background: #999 !important;
  color: #FFF !important; }
  .vs-btn-back:hover {
    background-color: #807f7f; }

.vs-btn-icon-back {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-back .__icon {
    color: #999;
    font-size: 16px; }
  .vs-btn-icon-back:hover {
    background-color: #ddd; }
    .vs-btn-icon-back:hover .__icon {
      color: #807f7f; }

.vs-btn-action-back:not(.vs-modal-btn) .__icon {
  color: #999; }

.vs-btn-action-back:not(.vs-modal-btn):hover .__icon {
  color: #807f7f; }

.vs-btn-action-back.vs-modal-btn {
  background-color: #999; }
  .vs-btn-action-back.vs-modal-btn:hover {
    background-color: #807f7f; }

.vs-btn-lock {
  background: #ff8800 !important;
  color: #FFF !important; }
  .vs-btn-lock:hover {
    background-color: #cc6d00; }

.vs-btn-icon-lock {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-lock .__icon {
    color: #ff8800;
    font-size: 16px; }
  .vs-btn-icon-lock:hover {
    background-color: #ddd; }
    .vs-btn-icon-lock:hover .__icon {
      color: #cc6d00; }

.vs-btn-action-lock:not(.vs-modal-btn) .__icon {
  color: #ff8800; }

.vs-btn-action-lock:not(.vs-modal-btn):hover .__icon {
  color: #cc6d00; }

.vs-btn-action-lock.vs-modal-btn {
  background-color: #ff8800; }
  .vs-btn-action-lock.vs-modal-btn:hover {
    background-color: #cc6d00; }

.vs-btn-unlock {
  background: #2552e7 !important;
  color: #FFF !important; }
  .vs-btn-unlock:hover {
    background-color: #163ec3; }

.vs-btn-icon-unlock {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-unlock .__icon {
    color: #2552e7;
    font-size: 16px; }
  .vs-btn-icon-unlock:hover {
    background-color: #ddd; }
    .vs-btn-icon-unlock:hover .__icon {
      color: #163ec3; }

.vs-btn-action-unlock:not(.vs-modal-btn) .__icon {
  color: #2552e7; }

.vs-btn-action-unlock:not(.vs-modal-btn):hover .__icon {
  color: #163ec3; }

.vs-btn-action-unlock.vs-modal-btn {
  background-color: #2552e7; }
  .vs-btn-action-unlock.vs-modal-btn:hover {
    background-color: #163ec3; }

.vs-btn-print {
  background: #2552e7 !important;
  color: #FFF !important; }
  .vs-btn-print:hover {
    background-color: #163ec3; }

.vs-btn-icon-print {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-print .__icon {
    color: #2552e7;
    font-size: 16px; }
  .vs-btn-icon-print:hover {
    background-color: #ddd; }
    .vs-btn-icon-print:hover .__icon {
      color: #163ec3; }

.vs-btn-action-print:not(.vs-modal-btn) .__icon {
  color: #2552e7; }

.vs-btn-action-print:not(.vs-modal-btn):hover .__icon {
  color: #163ec3; }

.vs-btn-action-print.vs-modal-btn {
  background-color: #2552e7; }
  .vs-btn-action-print.vs-modal-btn:hover {
    background-color: #163ec3; }

.vs-btn-config {
  background: #666 !important;
  color: #FFF !important; }
  .vs-btn-config:hover {
    background-color: #4d4c4c; }

.vs-btn-icon-config {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-config .__icon {
    color: #666;
    font-size: 16px; }
  .vs-btn-icon-config:hover {
    background-color: #ddd; }
    .vs-btn-icon-config:hover .__icon {
      color: #4d4c4c; }

.vs-btn-action-config:not(.vs-modal-btn) .__icon {
  color: #666; }

.vs-btn-action-config:not(.vs-modal-btn):hover .__icon {
  color: #4d4c4c; }

.vs-btn-action-config.vs-modal-btn {
  background-color: #666; }
  .vs-btn-action-config.vs-modal-btn:hover {
    background-color: #4d4c4c; }

.vs-btn-report {
  background: #7c8fce !important;
  color: #FFF !important; }
  .vs-btn-report:hover {
    background-color: #576fc0; }

.vs-btn-icon-report {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-report .__icon {
    color: #7c8fce;
    font-size: 16px; }
  .vs-btn-icon-report:hover {
    background-color: #ddd; }
    .vs-btn-icon-report:hover .__icon {
      color: #576fc0; }

.vs-btn-action-report:not(.vs-modal-btn) .__icon {
  color: #7c8fce; }

.vs-btn-action-report:not(.vs-modal-btn):hover .__icon {
  color: #576fc0; }

.vs-btn-action-report.vs-modal-btn {
  background-color: #7c8fce; }
  .vs-btn-action-report.vs-modal-btn:hover {
    background-color: #576fc0; }

.vs-btn-validate {
  background: #14b545 !important;
  color: #FFF !important; }
  .vs-btn-validate:hover {
    background-color: #0f8733; }

.vs-btn-icon-validate {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-validate .__icon {
    color: #14b545;
    font-size: 16px; }
  .vs-btn-icon-validate:hover {
    background-color: #ddd; }
    .vs-btn-icon-validate:hover .__icon {
      color: #0f8733; }

.vs-btn-action-validate:not(.vs-modal-btn) .__icon {
  color: #14b545; }

.vs-btn-action-validate:not(.vs-modal-btn):hover .__icon {
  color: #0f8733; }

.vs-btn-action-validate.vs-modal-btn {
  background-color: #14b545; }
  .vs-btn-action-validate.vs-modal-btn:hover {
    background-color: #0f8733; }

.vs-btn-view {
  background: #14b545 !important;
  color: #FFF !important; }
  .vs-btn-view:hover {
    background-color: #0f8733; }

.vs-btn-icon-view {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-view .__icon {
    color: #14b545;
    font-size: 16px; }
  .vs-btn-icon-view:hover {
    background-color: #ddd; }
    .vs-btn-icon-view:hover .__icon {
      color: #0f8733; }

.vs-btn-action-view:not(.vs-modal-btn) .__icon {
  color: #14b545; }

.vs-btn-action-view:not(.vs-modal-btn):hover .__icon {
  color: #0f8733; }

.vs-btn-action-view.vs-modal-btn {
  background-color: #14b545; }
  .vs-btn-action-view.vs-modal-btn:hover {
    background-color: #0f8733; }

.vs-btn-data {
  background: #1c752d !important;
  color: #FFF !important; }
  .vs-btn-data:hover {
    background-color: #124c1d; }

.vs-btn-icon-data {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-data .__icon {
    color: #1c752d;
    font-size: 16px; }
  .vs-btn-icon-data:hover {
    background-color: #ddd; }
    .vs-btn-icon-data:hover .__icon {
      color: #124c1d; }

.vs-btn-action-data:not(.vs-modal-btn) .__icon {
  color: #1c752d; }

.vs-btn-action-data:not(.vs-modal-btn):hover .__icon {
  color: #124c1d; }

.vs-btn-action-data.vs-modal-btn {
  background-color: #1c752d; }
  .vs-btn-action-data.vs-modal-btn:hover {
    background-color: #124c1d; }

.vs-btn-light {
  background: #fff !important;
  color: #FFF !important; }
  .vs-btn-light:hover {
    background-color: #e6e5e5; }

.vs-btn-icon-light {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-light .__icon {
    color: #fff;
    font-size: 16px; }
  .vs-btn-icon-light:hover {
    background-color: #ddd; }
    .vs-btn-icon-light:hover .__icon {
      color: #e6e5e5; }

.vs-btn-action-light:not(.vs-modal-btn) .__icon {
  color: #fff; }

.vs-btn-action-light:not(.vs-modal-btn):hover .__icon {
  color: #e6e5e5; }

.vs-btn-action-light.vs-modal-btn {
  background-color: #fff; }
  .vs-btn-action-light.vs-modal-btn:hover {
    background-color: #e6e5e5; }

.vs-btn-auto {
  background: #97096a !important;
  color: #FFF !important; }
  .vs-btn-auto:hover {
    background-color: #670648; }

.vs-btn-icon-auto {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-auto .__icon {
    color: #97096a;
    font-size: 16px; }
  .vs-btn-icon-auto:hover {
    background-color: #ddd; }
    .vs-btn-icon-auto:hover .__icon {
      color: #670648; }

.vs-btn-action-auto:not(.vs-modal-btn) .__icon {
  color: #97096a; }

.vs-btn-action-auto:not(.vs-modal-btn):hover .__icon {
  color: #670648; }

.vs-btn-action-auto.vs-modal-btn {
  background-color: #97096a; }
  .vs-btn-action-auto.vs-modal-btn:hover {
    background-color: #670648; }

.vs-btn-scheduled {
  background: #331bb0 !important;
  color: #FFF !important; }
  .vs-btn-scheduled:hover {
    background-color: #261484; }

.vs-btn-icon-scheduled {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-scheduled .__icon {
    color: #331bb0;
    font-size: 16px; }
  .vs-btn-icon-scheduled:hover {
    background-color: #ddd; }
    .vs-btn-icon-scheduled:hover .__icon {
      color: #261484; }

.vs-btn-action-scheduled:not(.vs-modal-btn) .__icon {
  color: #331bb0; }

.vs-btn-action-scheduled:not(.vs-modal-btn):hover .__icon {
  color: #261484; }

.vs-btn-action-scheduled.vs-modal-btn {
  background-color: #331bb0; }
  .vs-btn-action-scheduled.vs-modal-btn:hover {
    background-color: #261484; }

.vs-btn-prioritize {
  background: #9433df !important;
  color: #FFF !important; }
  .vs-btn-prioritize:hover {
    background-color: #7a1ec1; }

.vs-btn-icon-prioritize {
  border: 0;
  background-color: transparent; }
  .vs-btn-icon-prioritize .__icon {
    color: #9433df;
    font-size: 16px; }
  .vs-btn-icon-prioritize:hover {
    background-color: #ddd; }
    .vs-btn-icon-prioritize:hover .__icon {
      color: #7a1ec1; }

.vs-btn-action-prioritize:not(.vs-modal-btn) .__icon {
  color: #9433df; }

.vs-btn-action-prioritize:not(.vs-modal-btn):hover .__icon {
  color: #7a1ec1; }

.vs-btn-action-prioritize.vs-modal-btn {
  background-color: #9433df; }
  .vs-btn-action-prioritize.vs-modal-btn:hover {
    background-color: #7a1ec1; }

.vs-bulk-select-wrapper {
  position: relative;
  border: 1px solid #ddd;
  display: inline-block;
  padding-right: 22px;
  height: 24px;
  padding-top: 3px;
  padding-left: 3px; }
  .vs-bulk-select-wrapper .vs-btn.vs-bulk-select-btn {
    position: absolute;
    height: 100%;
    width: 22px;
    font-size: 14px;
    color: #666;
    top: 0;
    right: 0;
    border: 0;
    background-color: transparent; }

body .custom-autocomplete-button {
  font-size: 0.8em;
  width: 22px; }

.button-group button {
  margin-bottom: 0.5em; }

body .ui-table .ui-table-thead > tr > th {
  color: #fff; }

body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #f5f5f5;
  color: #666; }

body .ui-treetable .ui-treetable-thead > tr > th {
  color: #fff; }

body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
  background-color: #f5f5f5;
  color: #666; }

body .ui-table .ui-table-tbody tr.row-selected {
  background-color: #eaf5ff; }

body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tbody > tr > td,
body .ui-table .ui-table-tfoot > tr > td {
  padding: 8px 5px;
  outline: none; }

p-table .vs-table-paginator {
  font-weight: normal;
  text-align: left; }

.vs-table-wrapper {
  overflow: hidden;
  height: inherit; }

p-table p-sorticon {
  font-size: 10px; }

.vs-header-cell {
  display: flex;
  justify-content: space-between; }
  .vs-header-cell .__title + .__filter {
    margin-left: 4px; }
  .vs-header-cell .__title {
    flex: 1 10; }
  .vs-header-cell .ui-multiselect {
    background-color: transparent;
    border: 0; }
  .vs-header-cell .ui-multiselect-label-container {
    display: none; }
  .vs-header-cell .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
    box-shadow: none;
    border: 0; }
  .vs-header-cell .ui-multiselect .ui-multiselect-trigger {
    position: static;
    line-height: inherit;
    width: 16px; }
  .vs-header-cell .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
    position: static;
    margin: 0; }

.vs-table-filter-container .__filter-icon {
  padding-left: 8px; }

.vs-table-filter-overlay-wrapper {
  position: fixed;
  display: block !important;
  text-align: left;
  width: 220px;
  font-weight: 400; }
  .vs-table-filter-overlay-wrapper .ui-overlaypanel-content {
    padding: 0; }

.vs-table-filter-overlay .__filter-area {
  padding: 5px 10px 10px;
  border-bottom: 1px solid #ddd; }
  .vs-table-filter-overlay .__filter-area .__title {
    font-weight: bold;
    margin-bottom: 5px; }

.vs-table-filter-overlay .__filter-area.area-1 .__operator {
  display: block; }

.vs-table-filter-overlay .__filter-area.area-1 .__value {
  margin-top: 5px;
  width: 100%; }

.vs-table-filter-overlay .__filter-area.area-1 .__connective-block {
  padding: 5px 0; }
  .vs-table-filter-overlay .__filter-area.area-1 .__connective-block p-radioButton {
    display: inline-block;
    margin-right: 10px; }

.vs-table-filter-overlay .__filter-area.area-2 .__search-box {
  width: 100%; }

.vs-table-filter-overlay .__filter-area.area-2 .__col-values-actions {
  padding: 5px 0;
  text-align: right; }
  .vs-table-filter-overlay .__filter-area.area-2 .__col-values-actions .__action:hover {
    text-decoration: underline; }
  .vs-table-filter-overlay .__filter-area.area-2 .__col-values-actions .__action + .__action {
    margin-left: 5px; }

.vs-table-filter-overlay .__filter-area.area-2 .__col-value-items {
  max-height: 150px;
  border: 1px solid #ddd;
  padding: 3px 0;
  overflow-y: auto; }
  .vs-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox {
    display: flex;
    padding: 3px 8px; }
    .vs-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox > label {
      flex: 1; }
    .vs-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox:hover {
      background-color: #eeeeee; }

.vs-table-filter-overlay .__action-area {
  text-align: right;
  padding: 10px; }
  .vs-table-filter-overlay .__action-area .vs-btn + .vs-btn {
    margin-left: 5px; }

.vs-page-content-body-area {
  flex: 1 1;
  overflow: auto; }

.vs-data-table-area,
.vs-data-table-area-inner,
.vs-data-table-area .__main-table-area {
  height: 100%; }

.vs-data-table-area .__main-table-area {
  display: flex;
  flex-direction: column; }

p-table.vs-table {
  display: block; }
  p-table.vs-table,
  p-table.vs-table .ui-table,
  p-table.vs-table .ui-table-scrollable-view {
    height: 100%; }
  p-table.vs-table .ui-table {
    display: flex;
    flex-direction: column; }
  p-table.vs-table .ui-table-scrollable-wrapper {
    flex: 1 1;
    overflow: hidden; }
  p-table.vs-table .ui-table-scrollable-view {
    display: flex;
    flex-direction: column; }
  p-table.vs-table .ui-table-scrollable-body {
    flex: 1 10; }
  p-table.vs-table .ui-table-wrapper {
    overflow-x: auto; }
  p-table.vs-table.inherit-max-height,
  p-table.vs-table.inherit-max-height .ui-table,
  p-table.vs-table.inherit-max-height .ui-table-wrapper,
  p-table.vs-table.inherit-max-height .ui-table-scrollable-wrapper,
  p-table.vs-table.inherit-max-height .ui-table-scrollable-view {
    max-height: inherit; }
  p-table.vs-table .ui-widget-header {
    border: 0; }
  p-table.vs-table thead {
    background: #1ca7ff; }
  p-table.vs-table .ui-widget-overlay {
    background: #fff; }
  p-table.vs-table p-paginator .ui-paginator {
    text-align: right;
    padding: 5px 5px 3px 10px;
    border-top: 1px solid #e6eaee; }
    p-table.vs-table p-paginator .ui-paginator .ui-paginator-first,
    p-table.vs-table p-paginator .ui-paginator .ui-paginator-prev,
    p-table.vs-table p-paginator .ui-paginator .ui-paginator-next,
    p-table.vs-table p-paginator .ui-paginator .ui-paginator-last {
      margin-right: 0;
      margin-left: 0;
      min-width: 28px;
      min-height: 28px; }
    p-table.vs-table p-paginator .ui-paginator .ui-paginator-page {
      min-width: 28px;
      min-height: 28px;
      padding-left: 6px;
      padding-right: 6px; }
  p-table.vs-table td input[type="text"],
  p-table.vs-table td input[type="search"],
  p-table.vs-table td input[type="email"],
  p-table.vs-table td input[type="url"],
  p-table.vs-table td input[type="password"],
  p-table.vs-table td input[type="tel"],
  p-table.vs-table td input[type="number"],
  p-table.vs-table td input[type="date"] {
    padding-left: 6px;
    padding-right: 6px; }
  p-table.vs-table td .ui-dropdown {
    min-width: 0; }

p-treetable.vs-table {
  display: block; }
  p-treetable.vs-table,
  p-treetable.vs-table .ui-table {
    width: 100%; }
  p-treetable.vs-table .ui-table-wrapper {
    overflow-x: auto; }
  p-treetable.vs-table.inherit-max-height,
  p-treetable.vs-table.inherit-max-height .ui-table,
  p-treetable.vs-table.inherit-max-height .ui-table-wrapper,
  p-treetable.vs-table.inherit-max-height .ui-table-scrollable-wrapper,
  p-treetable.vs-table.inherit-max-height .ui-table-scrollable-view {
    max-height: inherit; }
  p-treetable.vs-table .ui-widget-header {
    border: 0; }
  p-treetable.vs-table thead {
    background: #1ca7ff; }
  p-treetable.vs-table .ui-widget-overlay {
    background: #fff; }
  p-treetable.vs-table p-paginator .ui-paginator {
    text-align: right;
    padding: 10px 15px; }
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-first,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-prev,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-next,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-last {
      margin-right: 0;
      margin-left: 0;
      min-width: 28px;
      min-height: 28px; }
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-page {
      min-width: 28px;
      min-height: 28px;
      padding-left: 6px;
      padding-right: 6px; }
  p-treetable.vs-table td input[type="text"],
  p-treetable.vs-table td input[type="search"],
  p-treetable.vs-table td input[type="email"],
  p-treetable.vs-table td input[type="url"],
  p-treetable.vs-table td input[type="password"],
  p-treetable.vs-table td input[type="tel"],
  p-treetable.vs-table td input[type="number"],
  p-treetable.vs-table td input[type="date"] {
    padding-left: 6px;
    padding-right: 6px; }
  p-treetable.vs-table td .ui-dropdown {
    min-width: 0; }

body .ui-table .ui-table-tbody > tr.highlight-border-bottom > td {
  border-bottom: 1px solid #bbb; }

body .ui-table .ui-table-tbody > tr.highlight > td {
  border: 1px solid #bbb; }

body .ui-table .ui-table-tbody > tr.highlight {
  background-color: #f2f2f2; }

body .ui-table .ui-table-tbody > tr:nth-child(even).highlight {
  background-color: #e9e9e9; }

body .ui-table span.ui-sortable-column.ui-state-highlight {
  background-color: transparent;
  outline: none; }

body .ui-table span.ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: white; }

p-table.align-cell-top .ui-table .ui-table-tbody > tr > td {
  vertical-align: top; }

p-table.no-border > .ui-table tr > td,
p-table.no-border > .ui-table tr > th {
  border: 0; }

.vs-table-footer {
  background: #fff;
  width: 100%;
  align-items: center;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 15px; }

body .ui-table .ui-table-tbody > tr > td.vs-td-sunday {
  background-color: #d9d9d9; }

body .ui-treetable .ui-treetable-thead > tr > th {
  color: #fff; }

body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
  background-color: #f5f5f5;
  color: #666; }

body .ui-treetable .ui-treetable-thead > tr > th {
  color: #fff; }

body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
  background-color: #f5f5f5;
  color: #666; }

body .ui-treetable .ui-treetable-tbody tr.row-selected {
  background-color: #eaf5ff; }

body .ui-treetable .ui-treetable-thead > tr > th,
body .ui-treetable .ui-treetable-tbody > tr > td,
body .ui-treetable .ui-treetable-tfoot > tr > td {
  padding: 8px 5px;
  outline: none; }

p-table .vs-table-paginator {
  font-weight: normal;
  text-align: left; }

.vs-table-wrapper {
  overflow: hidden;
  height: inherit; }

p-table p-sorticon {
  font-size: 10px; }

.vs-header-cell {
  display: flex;
  justify-content: space-between; }
  .vs-header-cell .__title + .__filter {
    margin-left: 4px; }
  .vs-header-cell .__title {
    flex: 1 10; }
  .vs-header-cell .ui-multiselect {
    background-color: transparent;
    border: 0; }
  .vs-header-cell .ui-multiselect-label-container {
    display: none; }
  .vs-header-cell .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
    box-shadow: none;
    border: 0; }
  .vs-header-cell .ui-multiselect .ui-multiselect-trigger {
    position: static;
    line-height: inherit;
    width: 16px; }
  .vs-header-cell .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
    position: static;
    margin: 0; }

.vs-table-filter-container .__filter-icon {
  padding-left: 8px; }

.vs-table-filter-overlay-wrapper {
  position: fixed;
  display: block !important;
  text-align: left;
  width: 220px;
  font-weight: 400; }
  .vs-table-filter-overlay-wrapper .ui-overlaypanel-content {
    padding: 0; }

.vs-table-filter-overlay .__filter-area {
  padding: 5px 10px 10px;
  border-bottom: 1px solid #ddd; }
  .vs-table-filter-overlay .__filter-area .__title {
    font-weight: bold;
    margin-bottom: 5px; }

.vs-table-filter-overlay .__filter-area.area-1 .__operator {
  display: block; }

.vs-table-filter-overlay .__filter-area.area-1 .__value {
  margin-top: 5px;
  width: 100%; }

.vs-table-filter-overlay .__filter-area.area-1 .__connective-block {
  padding: 5px 0; }
  .vs-table-filter-overlay .__filter-area.area-1 .__connective-block p-radioButton {
    display: inline-block;
    margin-right: 10px; }

.vs-table-filter-overlay .__filter-area.area-2 .__search-box {
  width: 100%; }

.vs-table-filter-overlay .__filter-area.area-2 .__col-values-actions {
  padding: 5px 0;
  text-align: right; }
  .vs-table-filter-overlay .__filter-area.area-2 .__col-values-actions .__action:hover {
    text-decoration: underline; }
  .vs-table-filter-overlay .__filter-area.area-2 .__col-values-actions .__action + .__action {
    margin-left: 5px; }

.vs-table-filter-overlay .__filter-area.area-2 .__col-value-items {
  max-height: 150px;
  border: 1px solid #ddd;
  padding: 3px 0;
  overflow-y: auto; }
  .vs-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox {
    display: flex;
    padding: 3px 8px; }
    .vs-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox > label {
      flex: 1; }
    .vs-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox:hover {
      background-color: #eeeeee; }

.vs-table-filter-overlay .__action-area {
  text-align: right;
  padding: 10px; }
  .vs-table-filter-overlay .__action-area .vs-btn + .vs-btn {
    margin-left: 5px; }

.vs-page-content-body-area {
  flex: 1 1;
  overflow: auto; }

.vs-data-table-area,
.vs-data-table-area-inner,
.vs-data-table-area .__main-table-area {
  height: 100%; }

.vs-data-table-area .__main-table-area {
  display: flex;
  flex-direction: column; }

p-treetable.vs-table {
  display: block; }
  p-treetable.vs-table,
  p-treetable.vs-table .ui-treetable,
  p-treetable.vs-table .ui-treetable-scrollable-view {
    height: 100%; }
  p-treetable.vs-table .ui-treetable {
    display: flex;
    flex-direction: column; }
  p-treetable.vs-table .ui-treetable-scrollable-wrapper {
    flex: 1 1;
    overflow: hidden; }
  p-treetable.vs-table .ui-treetable-scrollable-view {
    display: flex;
    flex-direction: column; }
  p-treetable.vs-table .ui-treetable-scrollable-body {
    flex: 1 10; }
  p-treetable.vs-table .ui-treetable-wrapper {
    overflow-x: auto; }
  p-treetable.vs-table.inherit-max-height,
  p-treetable.vs-table.inherit-max-height .ui-treetable,
  p-treetable.vs-table.inherit-max-height .ui-treetable-wrapper,
  p-treetable.vs-table.inherit-max-height .ui-treetable-scrollable-wrapper,
  p-treetable.vs-table.inherit-max-height .ui-treetable-scrollable-view {
    max-height: inherit; }
  p-treetable.vs-table .ui-widget-header {
    border: 0; }
  p-treetable.vs-table thead {
    background: #1ca7ff; }
  p-treetable.vs-table .ui-widget-overlay {
    background: #fff; }
  p-treetable.vs-table p-paginator .ui-paginator {
    text-align: right;
    padding: 5px 5px 3px 10px;
    border-top: 1px solid #e6eaee; }
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-first,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-prev,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-next,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-last {
      margin-right: 0;
      margin-left: 0;
      min-width: 28px;
      min-height: 28px; }
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-page {
      min-width: 28px;
      min-height: 28px;
      padding-left: 6px;
      padding-right: 6px; }
  p-treetable.vs-table td input[type="text"],
  p-treetable.vs-table td input[type="search"],
  p-treetable.vs-table td input[type="email"],
  p-treetable.vs-table td input[type="url"],
  p-treetable.vs-table td input[type="password"],
  p-treetable.vs-table td input[type="tel"],
  p-treetable.vs-table td input[type="number"],
  p-treetable.vs-table td input[type="date"] {
    padding-left: 6px;
    padding-right: 6px; }
  p-treetable.vs-table td .ui-dropdown {
    min-width: 0; }

p-treetable.vs-table {
  display: block; }
  p-treetable.vs-table,
  p-treetable.vs-table .ui-treetable {
    width: 100%; }
  p-treetable.vs-table .ui-treetable-wrapper {
    overflow-x: auto; }
  p-treetable.vs-table.inherit-max-height,
  p-treetable.vs-table.inherit-max-height .ui-treetable,
  p-treetable.vs-table.inherit-max-height .ui-treetable-wrapper,
  p-treetable.vs-table.inherit-max-height .ui-treetable-scrollable-wrapper,
  p-treetable.vs-table.inherit-max-height .ui-treetable-scrollable-view {
    max-height: inherit; }
  p-treetable.vs-table .ui-widget-header {
    border: 0; }
  p-treetable.vs-table thead {
    background: #1ca7ff; }
  p-treetable.vs-table .ui-widget-overlay {
    background: #fff; }
  p-treetable.vs-table p-paginator .ui-paginator {
    text-align: right;
    padding: 10px 15px; }
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-first,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-prev,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-next,
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-last {
      margin-right: 0;
      margin-left: 0;
      min-width: 28px;
      min-height: 28px; }
    p-treetable.vs-table p-paginator .ui-paginator .ui-paginator-page {
      min-width: 28px;
      min-height: 28px;
      padding-left: 6px;
      padding-right: 6px; }
  p-treetable.vs-table td input[type="text"],
  p-treetable.vs-table td input[type="search"],
  p-treetable.vs-table td input[type="email"],
  p-treetable.vs-table td input[type="url"],
  p-treetable.vs-table td input[type="password"],
  p-treetable.vs-table td input[type="tel"],
  p-treetable.vs-table td input[type="number"],
  p-treetable.vs-table td input[type="date"] {
    padding-left: 6px;
    padding-right: 6px; }
  p-treetable.vs-table td .ui-dropdown {
    min-width: 0; }

body .ui-treetable .ui-treetable-tbody > tr.highlight-border-bottom > td {
  border-bottom: 1px solid #bbb; }

body .ui-treetable .ui-treetable-tbody > tr.highlight > td {
  border: 1px solid #bbb; }

body .ui-treetable .ui-treetable-tbody > tr.highlight {
  background-color: #f2f2f2; }

body .ui-treetable .ui-treetable-tbody > tr:nth-child(even).highlight {
  background-color: #e9e9e9; }

body .ui-treetable span.ui-sortable-column.ui-state-highlight {
  background-color: transparent;
  outline: none; }

body .ui-treetable span.ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: white; }

p-table.align-cell-top .ui-treetable .ui-treetable-tbody > tr > td {
  vertical-align: top; }

p-table.no-border > .ui-treetable tr > td,
p-table.no-border > .ui-treetable tr > th {
  border: 0; }

.vs-table-footer {
  background: #fff;
  width: 100%;
  align-items: center;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 15px; }

body .ui-treetable .ui-treetable-tbody > tr > td.vs-td-sunday {
  background-color: #d9d9d9; }

/* Customize Paging */
body .custom-paginator .ui-widget-header {
  font-weight: normal; }

body .custom-paginator .ui-paginator-element {
  border: none; }
  body .custom-paginator .ui-paginator-element.ui-state-disabled {
    background: none;
    color: #9e9e9e; }

body .custom-paginator .ui-paginator-pages .ui-paginator-page {
  border: none; }

body .custom-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  border: none;
  background-color: #f3f3f3;
  color: #579cd8; }
  body .custom-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active:enabled:hover {
    background-color: #ccc; }
  body .custom-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active:focus {
    background-color: #f3f3f3;
    border: none;
    box-shadow: none; }

/* End custom paging */
body
body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: #285f8f; }

.ui-fileupload .ui-fileupload-content {
  background-color: #fff;
  text-align: center;
  position: absolute;
  padding: 0;
  border: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); }

.ui-fileupload .choose-file {
  background-color: #d9ebf7;
  padding: 5px;
  border-radius: 0; }

.ui-fileupload .ui-progressbar {
  top: 0; }

.ui-fileupload .ui-fileupload-choose:not(.ui-state-disabled):hover,
.ui-fileupload .ui-fileupload-choose.ui-state-focus {
  background-color: #2d6da3; }

.ui-fileupload .ui-widget-header {
  background-color: inherit;
  border: none; }

.vs-status {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  padding: 5px 7px; }

.vs-status-doing {
  background: #1ca7ff; }

.text-status-doing {
  color: #1ca7ff; }

.vs-status-response {
  background: #7c8fce; }

.text-status-response {
  color: #7c8fce; }

.vs-status-done {
  background: #004a80; }

.text-status-done {
  color: #004a80; }

.vs-status-waiting {
  background: #e8b837; }

.text-status-waiting {
  color: #e8b837; }

.vs-status-valid {
  background: #65bd43; }

.text-status-valid {
  color: #65bd43; }

.vs-status-expire {
  background: #f44c4c; }

.text-status-expire {
  color: #f44c4c; }

.vs-status-lock {
  background: #ff8800; }

.text-status-lock {
  color: #ff8800; }

.vs-status-cancel {
  background: #999; }

.text-status-cancel {
  color: #999; }

.vs-status-info {
  background: #2e9fe0; }

.text-status-info {
  color: #2e9fe0; }

.vs-status-leave {
  background: #973707; }

.text-status-leave {
  color: #973707; }

.vs-status-pause {
  background: #b44400; }

.text-status-pause {
  color: #b44400; }

.vs-status-stop {
  background: #e8822f; }

.text-status-stop {
  color: #e8822f; }

.vs-status-back {
  background: #666; }

.text-status-back {
  color: #666; }

body .ui-tree .ui-treenode .ui-treenode-content {
  padding-top: 2px;
  padding-bottom: 6px; }

.vs-data-tree.hide-search-input .ui-tree-filter-container {
  display: none; }

.vs-data-tree .ui-tree {
  border: 0;
  width: auto; }

.vs-data-tree .ui-treenode-content {
  outline: none;
  border: 1px solid transparent; }

.vs-data-tree .ui-treenode-icon {
  padding-left: 3px;
  padding-right: 5px; }

.vs-data-tree:not([selectionmode="checkbox"]) .ui-treenode-content.ui-treenode-content-selected {
  border: 1px solid #1ca7ff;
  background-color: rgba(28, 167, 255, 0.2); }

.vs-data-tree:not([selectionmode="checkbox"]) .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: transparent;
  color: inherit; }

.vs-data-tree[selectionmode="checkbox"] .ui-treenode .ui-treenode-content {
  padding: 0; }
  .vs-data-tree[selectionmode="checkbox"] .ui-treenode .ui-treenode-content .ui-treenode-label {
    padding: 5px; }

p-calendar .ui-calendar .ui-inputtext {
  border-radius: 0px;
  box-shadow: none;
  float: left; }

p-calendar .ui-calendar .ui-button {
  border-radius: 0px; }

p-tabView.vs-tab-view.style-1 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  outline: none; }

p-tabView.vs-tab-view.style-1 .ui-tabview.ui-tabview-top .ui-tabview-nav li a {
  color: #666;
  padding: 5px 10px; }

p-tabView.vs-tab-view.style-1 .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a {
  font-weight: 700;
  color: #212529; }

p-tabView.vs-tab-view.style-1 .ui-tabview-panel {
  padding: 0; }

p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav {
  display: flex;
  flex-wrap: wrap; }
  p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    background-color: #1ca7ff;
    border: none;
    margin-right: 0;
    border-radius: 0;
    flex: 1 0 110px;
    max-width: 200px; }
    @media screen and (max-width: 640px) {
      p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        width: 100%; } }
    p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li a {
      color: #fff;
      font-weight: bold; }
    p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li:focus {
      outline: none; }
    p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
      background-color: #fff; }
      p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a {
        color: #0a89bf; }
    p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li:hover a {
      color: #666666; }

p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .vs-btn.__btn-accordion {
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 0; }

p-tabView.vs-tab-view-info-account .ui-tabview.ui-tabview-top .vs-btn.activated {
  background-color: #14b545; }

p-tabView.vs-tab-view-info-account .ui-tabview-panel {
  padding: 0; }
  p-tabView.vs-tab-view-info-account .ui-tabview-panel .__content-body {
    border: 1px solid #ddd; }
  p-tabView.vs-tab-view-info-account .ui-tabview-panel .__top-tabview-action {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 5px; }

.vs-tooltip .ui-tooltip-text {
  word-break: break-word; }

.layout-wrapper .topbar .topbar-right,
.layout-wrapper .topbar .topbar-left {
  background: #0084ff; }

.layout-wrapper .topbar,
.layout-wrapper .topbar .topbar-right,
.layout-wrapper .topbar .topbar-left {
  height: 50px; }

.layout-wrapper .topbar .topbar-right {
  padding: 5px 15px; }

.layout-wrapper .topbar .topbar-left {
  width: 240px; }
  .layout-wrapper .topbar .topbar-left .topbar-logo {
    margin: 10px;
    margin-top: 5px;
    height: auto;
    width: 50%; }
  .layout-wrapper .topbar .topbar-left .topbar-title {
    padding: 4px 13px 4px 0;
    height: 40px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .layout-wrapper .topbar .topbar-left .topbar-title .topbar-description {
      font-size: 12px;
      font-weight: 700; }
    .layout-wrapper .topbar .topbar-left .topbar-title .topbar-owner {
      font-size: 10px;
      margin-bottom: 0; }

.layout-wrapper .topbar .topbar-right #menu-button {
  left: 240px;
  top: 14px;
  width: 24px;
  height: 24px;
  margin-left: -12px; }
  .layout-wrapper .topbar .topbar-right #menu-button i {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .layout-wrapper .topbar .topbar-right #menu-button i:before {
    margin-top: -1px;
    margin-left: -1px; }

.layout-wrapper .topbar-items > li > a .topbar-badge {
  background-color: #e65025;
  font-weight: bold; }

.layout-wrapper .layout-menu li a i:first-child {
  width: 40px;
  text-align: center;
  font-size: 1.6em;
  margin-right: 5px; }

.layout-wrapper .layout-menu li a i:first-child,
.layout-wrapper .layout-menu li.active-menuitem > a i:first-child {
  color: #1ca7ff; }

.layout-wrapper .layout-menu li a span {
  line-height: 1.6em; }

.layout-wrapper .topbar-items > li.profile-item a .profile-image {
  border: 2px #fff solid;
  border-radius: 50%; }

@media (min-width: 1025px) {
  .layout-wrapper .topbar-items > li > ul.subsystem {
    width: 300px; }
    .layout-wrapper .topbar-items > li > ul.subsystem a.subsystem-item {
      width: 50%;
      display: inline-block; }
    .layout-wrapper .topbar-items > li > ul.subsystem:before {
      left: auto;
      right: 3px; }
  .layout-wrapper .topbar-items {
    margin: -5px 0 0;
    display: flex;
    flex-direction: row-reverse;
    height: 50px;
    align-items: center; }
    .layout-wrapper .topbar-items > li {
      float: none; }
  .layout-wrapper .topbar-items > li.profile-item a {
    top: 0; }
  .layout-wrapper .topbar-items > li .topbar-icon {
    font-size: 20px; }
  .layout-wrapper .topbar-items > li.profile-item a .profile-image {
    width: 32px;
    height: 32px;
    margin-top: 0;
    margin-left: 8px; }
  .layout-wrapper .topbar-items > li.profile-item {
    margin-left: 10px; }
    .layout-wrapper .topbar-items > li.profile-item .profile-item-inner {
      display: flex;
      align-items: center; }
      .layout-wrapper .topbar-items > li.profile-item .profile-item-inner .topbar-item-name {
        float: none; }
    .layout-wrapper .topbar-items > li.profile-item .topbar-item-name {
      white-space: nowrap; }
    .layout-wrapper .topbar-items > li.profile-item .topbar-item-role {
      line-height: 20px;
      white-space: nowrap; }
  .layout-wrapper .topbar-items > li.search-item .ui-inputtext {
    width: 250px; } }

@media (max-width: 1024px) {
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item > a {
    padding-left: 50px; }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item.active-top-menu > ul li a {
    padding-left: 5px; }
  .layout-wrapper .topbar-items > li.profile-item > ul {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  .layout-wrapper .topbar-items > li.profile-item .topbar-item-role {
    opacity: 0.7;
    font-size: 14px; }
  .layout-wrapper .topbar-items > li.profile-item .profile-image-wrapper {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    left: 15px; }
  .layout-wrapper .topbar-items > li.search-item .ui-inputtext {
    width: 100%;
    outline: none; } }

@media (min-width: 1025px) {
  .noti-item {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box; }
    .noti-item.un-read {
      background: #eaf5ff; }
  .layout-wrapper .topbar-items > li > ul.custom-notification {
    width: 350px; }
    .layout-wrapper .topbar-items > li > ul.custom-notification::before {
      left: 332px; }
  .noti-content {
    font-size: 0.9em; }
    .noti-content .noti-title {
      font-weight: bold;
      margin-bottom: 0.5em; }
    .noti-content .noti-message {
      margin-bottom: 0.5em;
      font-size: 12px;
      text-align: justify; }
    .noti-content .noti-time {
      font-size: 10px;
      text-align: right; }
  .noti-footer {
    text-align: center;
    font-size: 0.9em; }
  .noti-header {
    font-size: 0.9em;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd; }
  .layout-wrapper .layout-menu li div.noti-time i:first-child {
    margin-right: 0px;
    width: 15px;
    line-height: 15px; }
  .noti-avatar {
    float: left;
    height: 50px; }
    .noti-avatar img {
      width: 48px;
      height: 48px;
      border: 2px #fff solid;
      border-radius: 24px; } }

.layout-wrapper .layout-menu-container {
  top: 50px;
  padding-bottom: 90px;
  width: 240px;
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.25); }
  .layout-wrapper .layout-menu-container:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); }

.layout-wrapper .layout-menu-container .ui-scrollpanel .layout-main-menu {
  margin-top: 0;
  padding-bottom: 0; }

.vs-left-menu-area .menu-scroll-content-area .ui-scrollpanel-wrapper {
  z-index: 3; }

.vs-left-menu-area .menuitem-level-0 .ripplelink .menuitem-label {
  opacity: 0;
  transition: opacity .3s;
  visibility: hidden !important; }

.vs-left-menu-area:hover .menuitem-level-0 .ripplelink .menuitem-label {
  opacity: 1;
  visibility: visible !important; }

.vs-left-menu-area .search-area {
  position: absolute;
  width: calc(100% - 20px);
  height: 50px;
  left: 0;
  bottom: 40px;
  border-top: 1px solid #dfe7ef;
  margin: 0 10px; }
  .vs-left-menu-area .search-area .search-btn {
    border-radius: 0;
    height: 100%; }
  .vs-left-menu-area .search-area .search-input,
  .vs-left-menu-area .search-area .search-input .ui-autocomplete {
    display: block;
    width: 100%;
    height: 100%; }
  .vs-left-menu-area .search-area .search-input .ui-inputtext {
    padding-right: 30px;
    padding-left: 15px;
    width: 100%;
    height: 100%;
    outline: none;
    border: 0; }
    .vs-left-menu-area .search-area .search-input .ui-inputtext:enabled:focus:not(.ui-state-error) {
      border: 0;
      box-shadow: none; }
  .vs-left-menu-area .search-area .search-input .ui-autocomplete-items {
    padding: 10px 5px; }
  .vs-left-menu-area .search-area .search-input .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
    color: inherit;
    background-color: rgba(28, 167, 255, 0.3); }
  .vs-left-menu-area .search-area .search-input .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item mark {
    background: transparent;
    color: #1ca7ff; }
  .vs-left-menu-area .search-area .search-btn {
    position: absolute;
    right: -7px;
    top: 0;
    color: #888;
    width: 30px;
    font-size: 14px; }
    .vs-left-menu-area .search-area .search-btn, .vs-left-menu-area .search-area .search-btn:enabled:hover, .vs-left-menu-area .search-area .search-btn:enabled:active, .vs-left-menu-area .search-area .search-btn:enabled:focus {
      background-color: transparent;
      box-shadow: none; }

.layout-menu-static-desktop-active .menuitem-level-0 .ripplelink .menuitem-label {
  visibility: visible !important;
  opacity: 1; }

.vs-main-menu li > a.ripplelink,
.vs-main-menu li.activated.active-menuitem > a.ripplelink {
  color: inherit; }

.vs-main-menu li.activated > a.ripplelink {
  color: #0095da; }

.vs-main-menu li.activated > ul > li > a.ripplelink {
  margin: 0 25px;
  padding: 5px 24px 5px 38px;
  line-height: 35px; }
  .vs-main-menu li.activated > ul > li > a.ripplelink > span.icon_menu {
    left: 2px;
    color: #2196F3; }

.vs-main-menu li.activated > ul > li.activated > a.ripplelink {
  color: #0095da;
  background: #fff; }

.vs-main-menu li a.ripplelink i:first-child {
  margin-right: 0; }

.vs-main-menu a.ripplelink {
  cursor: pointer;
  position: relative; }
  .vs-main-menu a.ripplelink > .icon_menu > img {
    border-radius: 8px; }
  .vs-main-menu a.ripplelink > span.menuitem-label {
    line-height: 22px; }
  .vs-main-menu a.ripplelink.active {
    background-color: #f1f3f4;
    border-left-color: #0095da; }

.vs-main-menu .menuitem-level-0 > .ripplelink {
  padding: 0 0 0 50px;
  line-height: 55px; }
  .vs-main-menu .menuitem-level-0 > .ripplelink .menuitem-label {
    font-weight: 700; }
  .vs-main-menu .menuitem-level-0 > .ripplelink .menuitem-toggle-icon {
    color: inherit;
    position: absolute;
    line-height: 55px;
    width: 20px;
    right: 10px; }
  .vs-main-menu .menuitem-level-0 > .ripplelink .main-icon {
    position: absolute;
    width: 45px;
    line-height: 55px;
    left: 6px; }

.vs-main-menu .menuitem-level-1 > .ripplelink {
  padding: 5px 24px 5px 46px; }
  .vs-main-menu .menuitem-level-1 > .ripplelink .menuitem-toggle-icon,
  .vs-main-menu .menuitem-level-1 > .ripplelink .main-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    margin-top: 0;
    margin-right: 0;
    color: inherit;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 19px; }
  .vs-main-menu .menuitem-level-1 > .ripplelink .menuitem-toggle-icon {
    opacity: 0; }
  .vs-main-menu .menuitem-level-1 > .ripplelink .main-icon {
    font-size: 18px;
    opacity: 1; }

.vs-main-menu .menuitem-level-1:hover > .ripplelink {
  background-color: #f4f4f4; }

.vs-main-menu .menuitem-level-1.has-submenu:not(.allow-click) > .ripplelink .menuitem-toggle-icon {
  opacity: 1; }

.vs-main-menu .menuitem-level-1.has-submenu:not(.allow-click) > .ripplelink .main-icon {
  opacity: 0; }

@media (min-width: 1025px) {
  .vs-main-menu .menuitem-level-1 {
    position: relative; }
    .vs-main-menu .menuitem-level-1:hover > .ripplelink .menuitem-toggle-icon:before {
      -webkit-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      transform: rotate(90deg); }
    .vs-main-menu .menuitem-level-1:hover .submenu-level-2 {
      opacity: 1;
      left: 240px;
      width: 450px; } }

@media (max-width: 1024px) {
  .vs-main-menu .menuitem-level-1.activated > .ripplelink .menuitem-toggle-icon:before {
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transform: rotate(90deg); } }

.vs-main-menu .menuitem-level-2 .main-icon {
  display: none; }

@media (min-width: 1025px) {
  .vs-main-menu .submenu-level-2 {
    overflow-y: auto !important;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    position: fixed;
    left: 250px;
    top: 40px;
    z-index: 1001;
    width: 0;
    height: calc(100vh - 50px) !important;
    background: #99dbf5;
    transition: opacity .3s, left .3s;
    opacity: 0; }
  .vs-main-menu .menuitem-level-2 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative; }
    .vs-main-menu .menuitem-level-2 .pinned-icon {
      -webkit-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      position: absolute;
      left: 50%;
      z-index: 10;
      top: 28px;
      transform: translateX(7px);
      width: 30px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      opacity: 0;
      cursor: pointer; }
      .vs-main-menu .menuitem-level-2 .pinned-icon:before {
        position: absolute;
        line-height: 24px;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center; }
    .vs-main-menu .menuitem-level-2 > .ripplelink,
    .vs-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink {
      outline: none;
      padding: 30px 15px;
      text-align: center;
      border-left: 0; }
      .vs-main-menu .menuitem-level-2 > .ripplelink .decoration,
      .vs-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .decoration {
        -webkit-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
        background-color: #4facce;
        color: #fff;
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 10px;
        position: relative; }
      .vs-main-menu .menuitem-level-2 > .ripplelink .decorated-icon,
      .vs-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .decorated-icon {
        color: inherit; }
        .vs-main-menu .menuitem-level-2 > .ripplelink .decorated-icon:before,
        .vs-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .decorated-icon:before {
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 100%;
          font-size: 32px;
          display: block; }
      .vs-main-menu .menuitem-level-2 > .ripplelink .menuitem-label,
      .vs-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .menuitem-label {
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        color: #035378;
        line-height: 1.2em; }
    .vs-main-menu .menuitem-level-2:hover .pinned-icon {
      opacity: 1; }
    .vs-main-menu .menuitem-level-2:hover > .ripplelink,
    .vs-main-menu .menuitem-level-2:hover > .ripplelink.active-menuitem-routerlink {
      background: transparent; }
      .vs-main-menu .menuitem-level-2:hover > .ripplelink .decoration,
      .vs-main-menu .menuitem-level-2:hover > .ripplelink.active-menuitem-routerlink .decoration {
        background-color: #3394b7; }
      .vs-main-menu .menuitem-level-2:hover > .ripplelink .menuitem-label,
      .vs-main-menu .menuitem-level-2:hover > .ripplelink.active-menuitem-routerlink .menuitem-label {
        color: #023146; }
    .vs-main-menu .menuitem-level-2.pinned .pinned-icon {
      opacity: 1; } }

@media (max-width: 1024px) {
  .vs-main-menu .menuitem-level-2 > .ripplelink {
    padding-left: 60px; }
    .vs-main-menu .menuitem-level-2 > .ripplelink .decoraticon {
      display: none; } }

.vs-pinned-menu {
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  position: fixed;
  left: 250px;
  top: 40px;
  z-index: 1000;
  width: 0;
  height: calc(100vh - 50px);
  background: #99dbf5;
  transition: opacity .3s, left .3s;
  opacity: 0;
  padding: 0;
  margin: 0;
  list-style: none; }
  .vs-pinned-menu.show {
    opacity: 1;
    left: 240px;
    width: 450px; }
  .vs-pinned-menu li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative; }
    .vs-pinned-menu li .pinned-icon {
      -webkit-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      position: absolute;
      left: 50%;
      z-index: 10;
      top: 28px;
      transform: translateX(7px);
      width: 30px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      opacity: 0;
      cursor: pointer; }
      .vs-pinned-menu li .pinned-icon:before {
        position: absolute;
        line-height: 24px;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center; }
    .vs-pinned-menu li > .ripplelink,
    .vs-pinned-menu li > .ripplelink.active-menuitem-routerlink {
      display: block;
      outline: none;
      padding: 30px 15px;
      text-align: center;
      border-left: 0; }
      .vs-pinned-menu li > .ripplelink .decoration,
      .vs-pinned-menu li > .ripplelink.active-menuitem-routerlink .decoration {
        -webkit-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
        background-color: #4facce;
        color: #fff;
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 10px;
        position: relative; }
      .vs-pinned-menu li > .ripplelink .decorated-icon,
      .vs-pinned-menu li > .ripplelink.active-menuitem-routerlink .decorated-icon {
        color: inherit; }
        .vs-pinned-menu li > .ripplelink .decorated-icon:before,
        .vs-pinned-menu li > .ripplelink.active-menuitem-routerlink .decorated-icon:before {
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 100%;
          font-size: 32px;
          display: block; }
      .vs-pinned-menu li > .ripplelink .menuitem-label,
      .vs-pinned-menu li > .ripplelink.active-menuitem-routerlink .menuitem-label {
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        color: #035378;
        line-height: 1.2em; }
    .vs-pinned-menu li:hover .pinned-icon {
      opacity: 1; }
    .vs-pinned-menu li:hover > .ripplelink,
    .vs-pinned-menu li:hover > .ripplelink.active-menuitem-routerlink {
      background: transparent; }
      .vs-pinned-menu li:hover > .ripplelink .decoration,
      .vs-pinned-menu li:hover > .ripplelink.active-menuitem-routerlink .decoration {
        background-color: #3394b7; }
      .vs-pinned-menu li:hover > .ripplelink .menuitem-label,
      .vs-pinned-menu li:hover > .ripplelink.active-menuitem-routerlink .menuitem-label {
        color: #023146; }
    .vs-pinned-menu li.pinned .pinned-icon {
      opacity: 1; }

@media (min-width: 1025px) {
  .layout-wrapper.menu-layout-static.layout-menu-static-desktop-active .vs-left-menu-area,
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area {
    overflow: hidden;
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease; }
    .layout-wrapper.menu-layout-static.layout-menu-static-desktop-active .vs-left-menu-area > p-scrollpanel,
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area > p-scrollpanel {
      display: block;
      width: 240px;
      height: 100%; }
      .layout-wrapper.menu-layout-static.layout-menu-static-desktop-active .vs-left-menu-area > p-scrollpanel .menu-scroll-content,
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area > p-scrollpanel .menu-scroll-content {
        width: 230px;
        margin-right: 0;
        padding-right: 0; }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area {
    margin-left: 0;
    width: 66px; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area .submenu-level-1 {
      display: none; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area .search-area .search-input {
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area .search-area .search-btn {
      width: 66px;
      -webkit-transition: width 0.3s ease;
      -o-transition: width 0.3s ease;
      transition: width 0.3s ease; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area:hover {
      width: 240px; }
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area:hover .submenu-level-1 {
        display: block; }
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area:hover .search-area .search-input {
        opacity: 1; }
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-left-menu-area:hover .search-area .search-btn {
        width: 40px; }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .vs-page-content-area {
    margin-left: 66px; } }

@media (max-width: 1024px) {
  .layout-wrapper.menu-layout-static .layout-menu-container {
    margin-left: -308px; } }

.vs-page-content {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column; }

.vs-page-wrapper .vs-page-content-area {
  padding: 50px 0 0 0; }

@media (min-width: 1025px) {
  .layout-wrapper.menu-layout-static .layout-main {
    margin-left: 240px; } }

.vs-page-content-header-area {
  background-color: #fff;
  padding: 7.5px 0 0;
  margin-bottom: 7.5px; }
  .vs-page-content-header-area .__page-title {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 30px; }
  .vs-page-content-header-area .__search-box {
    position: relative;
    margin-bottom: 7.5px;
    width: calc(100% - 40px); }
    .vs-page-content-header-area .__search-box .__input {
      padding-right: 47px;
      width: 100%; }
    .vs-page-content-header-area .__search-box .__toggle-search-adv,
    .vs-page-content-header-area .__search-box .__search-btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 100%;
      top: 0;
      cursor: pointer; }
    .vs-page-content-header-area .__search-box .__toggle-search-adv {
      right: -40px;
      font-size: 16px; }
    .vs-page-content-header-area .__search-box .__search-btn {
      right: 0; }
  .vs-page-content-header-area .__search-filter-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; }
    .vs-page-content-header-area .__search-filter-wrapper > * {
      margin-left: 5px;
      margin-bottom: 5px; }
    .vs-page-content-header-area .__search-filter-wrapper .ui-dropdown,
    .vs-page-content-header-area .__search-filter-wrapper .ui-multiselect {
      min-width: 180px; }

.vs-data-table-area.no-padding {
  padding: 0; }

.vs-data-table-area .__left-table-sidebar,
.vs-data-table-area .__right-table-sidebar {
  background: #fff; }
  .vs-data-table-area .__left-table-sidebar .__sidebar-header,
  .vs-data-table-area .__right-table-sidebar .__sidebar-header {
    background-color: #1ca7ff;
    color: #fff;
    line-height: 16px;
    padding: 4px 10px; }

.vs-data-table-area .__top-table-action {
  background-color: #fff; }
  .vs-data-table-area .__top-table-action .__left-content {
    padding-left: 0; }
    .vs-data-table-area .__top-table-action .__left-content > * {
      vertical-align: top; }
      .vs-data-table-area .__top-table-action .__left-content > * .__icon {
        padding-top: 0px; }
    .vs-data-table-area .__top-table-action .__left-content .ui-dropdown {
      min-width: 180px; }
  .vs-data-table-area .__top-table-action .__change-view {
    text-align: right; }
    .vs-data-table-area .__top-table-action .__change-view .vs-btn {
      margin-left: 5px;
      margin-bottom: 5px;
      color: #fff;
      background: #aaa;
      border: 0;
      padding-left: 10px;
      padding-right: 10px;
      -webkit-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
      .vs-data-table-area .__top-table-action .__change-view .vs-btn:hover, .vs-data-table-area .__top-table-action .__change-view .vs-btn.activated {
        background-color: #14b545; }
  .vs-data-table-area .__top-table-action .__right-content {
    padding-right: 0; }
  .vs-data-table-area .__top-table-action .__action-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 30px; }
  .vs-data-table-area .__top-table-action .__tab-item-wrapper {
    height: 24px; }
  .vs-data-table-area .__top-table-action .__tab-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px; }
    .vs-data-table-area .__top-table-action .__tab-item .__icon {
      font-size: 18px;
      margin-right: 5px;
      line-height: 30px; }
    .vs-data-table-area .__top-table-action .__tab-item.activated, .vs-data-table-area .__top-table-action .__tab-item:hover {
      background-color: #ddd; }
  .vs-data-table-area .__top-table-action .__toggle-column-wrapper {
    position: relative; }
  .vs-data-table-area .__top-table-action .__column-list {
    position: absolute;
    max-height: 400px;
    overflow: auto;
    width: 250px;
    top: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    right: 0;
    z-index: 5;
    background: #fff;
    color: #212529;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3); }
    .vs-data-table-area .__top-table-action .__column-list .__column-item {
      padding: 5px 10px; }
      .vs-data-table-area .__top-table-action .__column-list .__column-item:hover {
        background-color: #ddd; }

.vs-data-tree-container .__data-tree-tools-area {
  padding: 10px;
  border-bottom: 1px solid #ddd; }
  .vs-data-tree-container .__data-tree-tools-area .__expanse-tree-node-btn {
    margin-right: 5px;
    border: 0;
    font-size: 12px;
    color: #fff;
    background-color: #aaa;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .vs-data-tree-container .__data-tree-tools-area .__expanse-tree-node-btn:hover, .vs-data-tree-container .__data-tree-tools-area .__expanse-tree-node-btn.activated {
      background-color: #14b545; }

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-size: 16px; }

.ui-dialog.ui-dialog-resizable {
  height: auto !important; }

.ui-dialog.ui-dialog-maximized {
  max-width: none !important;
  max-height: none !important; }
  .ui-dialog.ui-dialog-maximized .ui-dialog-content {
    max-height: none !important; }

.vs-modal.force-full-width .ui-dialog, .vs-modal.force-full-width.ui-dialog {
  width: 100% !important; }

.vs-modal .ui-dialog, .vs-modal.ui-dialog {
  background-color: #f5f5f5; }

.vs-modal.ui-dynamicdialog {
  background-color: #fff; }

.vs-modal .ui-dialog .ui-dialog-titlebar,
.vs-modal.ui-dialog .ui-dialog-titlebar {
  border-bottom: 0; }
  .vs-modal .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon,
  .vs-modal.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
    font-size: 20px; }

.vs-modal .vs-modal-tab {
  margin-top: -15px;
  display: block; }
  .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav {
    background-color: transparent; }
  .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li {
    margin-right: -3px;
    outline: none;
    border: 3px solid #ccc;
    border-radius: 0;
    border-bottom-color: #2e9fe0; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li a {
      font-weight: 700;
      color: #212529; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li:not(.ui-state-active) {
      background-color: #e6e6e6; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-active {
      border-width: 3px;
      border-color: #2e9fe0;
      border-bottom-color: #fff; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-active + li {
      border-left-color: #2e9fe0; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-disabled {
      color: #666; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li:first-of-type {
      border-left: 0; }
    .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-disabled a {
      color: #aaa; }
  .vs-modal .vs-modal-tab .ui-tabview .ui-tabview-panels {
    background-color: #fff;
    box-shadow: 0px 4px 5px #d9d9d9;
    border-top: 3px solid #1ca7ff;
    margin-top: -3px; }

.vs-modal.ui-dialog .ui-dialog-footer,
.vs-modal .ui-dialog .ui-dialog-footer {
  border: 0;
  background-color: transparent; }

.vs-modal .vs-modal-content-panel {
  background-color: #fff;
  padding: 15px;
  margin-top: -15px; }
  .vs-modal .vs-modal-content-panel.no-bgc {
    background-color: transparent; }
  .vs-modal .vs-modal-content-panel.no-drop-shadow {
    box-shadow: none; }
  .vs-modal .vs-modal-content-panel:not(.vs-pick-list-container):before {
    content: '';
    display: block;
    position: sticky;
    top: -15px;
    z-index: 100;
    margin: -15px -15px 15px; }
  .vs-modal .vs-modal-content-panel.vs-pick-list-container {
    border-top: 3px solid #1ca7ff; }
  .vs-modal .vs-modal-content-panel.no-padding {
    padding: 0; }
  .vs-modal .vs-modal-content-panel.no-padding:not(.vs-pick-list-container):before {
    margin: 0; }
  .vs-modal .vs-modal-content-panel form {
    width: 100%; }

.vs-modal.full-content-height .ui-dialog-content {
  height: 100vh;
  padding-top: 0; }

.vs-modal.full-content-height .vs-modal-content-panel {
  height: 100%;
  margin-top: 0;
  border-top: 3px solid #1ca7ff; }
  .vs-modal.full-content-height .vs-modal-content-panel:before {
    display: none; }

.vs-modal .vs-modal-content-panel .__flex-layout {
  height: 100%;
  display: flex; }
  .vs-modal .vs-modal-content-panel .__flex-layout.wrap {
    flex-wrap: wrap; }
  .vs-modal .vs-modal-content-panel .__flex-layout > div {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .vs-modal .vs-modal-content-panel .__flex-layout .__fx-sidebar-area {
    flex: 1 1 280px; }
  .vs-modal .vs-modal-content-panel .__flex-layout .__fx-main-area {
    flex: 5 1 500px; }
  .vs-modal .vs-modal-content-panel .__flex-layout .__fx-block-container {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .vs-modal .vs-modal-content-panel .__flex-layout .__fx-expanded-block {
    flex: 5;
    overflow: auto; }

.vs-modal p-footer .vs-modal-more-action-btn-wrapper {
  text-align: left; }
  .vs-modal p-footer .vs-modal-more-action-btn-wrapper .vs-custom-dropdown.delete-action {
    padding: 10px; }
    .vs-modal p-footer .vs-modal-more-action-btn-wrapper .vs-custom-dropdown.delete-action .__action {
      padding: 5px 10px;
      color: #212529;
      display: block;
      -webkit-transition: background-color 0.3s ease-out;
      -o-transition: background-color 0.3s ease-out;
      transition: background-color 0.3s ease-out; }
      .vs-modal p-footer .vs-modal-more-action-btn-wrapper .vs-custom-dropdown.delete-action .__action:hover {
        background-color: #f44c4c; }

.vs-modal p-footer button.vs-modal-btn {
  margin-right: 0;
  margin-left: 5px; }

.vs-modal-p-0 .vs-page-content-header-area {
  box-shadow: 0px 0px 5px 0px #aaa;
  z-index: 100000;
  position: relative;
  padding: 0;
  margin: 0; }

.vs-modal-p-0 .__top-table-action {
  padding-bottom: 0; }

.vs-modal-p-0 .vs-modal-tab .ui-tabview .ui-tabview-panel {
  padding: 0; }

.vs-modal-p-0 .vs-modal-tab .ui-tabview .tab-panel-p .ui-tabview-panel {
  padding: 15px; }

.vs-modal-tree .vs-modal-content-panel {
  padding: 0; }

.vs-modal-tree .ui-tree-selectable.ui-widget-content {
  border: none; }

.vs-modal-tree .vs-page-content-header-area {
  padding: 10px 7.5px 0;
  background-color: #fff;
  margin-bottom: 0; }

.vs-modal-tree .__top-table-header {
  color: #000000;
  background-color: #fff; }

.vs-modal-tree .vs-data-table-area-inner {
  background-color: #dddddd; }

.vs-modal-tree .__left-table-sidebar-wrapper {
  padding-left: 0;
  overflow: hidden; }

.vs-modal-tree .__table-wrapper {
  padding: 0;
  border-left: 1px solid #1ca7ff; }

.vs-modal-tree .list-tree {
  background-color: #fff;
  height: 445px;
  overflow-y: auto; }
  .vs-modal-tree .list-tree .ui-tree-filter {
    border-radius: 0; }
  .vs-modal-tree .list-tree ul.ui-tree-container {
    height: 397px; }

.vs-modal-tree .w-20 {
  min-width: 20%; }

.vs-pick-list-container {
  display: flex; }
  .vs-pick-list-container.vs-modal-content-panel {
    padding: 0; }
  .vs-pick-list-container .vs-pick-list-left-panel,
  .vs-pick-list-container .vs-pick-list-right-panel {
    padding: 0;
    flex-grow: 1; }
    .vs-pick-list-container .vs-pick-list-left-panel .__panel-content-header,
    .vs-pick-list-container .vs-pick-list-right-panel .__panel-content-header {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px; }
      .vs-pick-list-container .vs-pick-list-left-panel .__panel-content-header .__title,
      .vs-pick-list-container .vs-pick-list-right-panel .__panel-content-header .__title {
        margin-top: 0;
        margin-bottom: 15px; }
    .vs-pick-list-container .vs-pick-list-left-panel .__panel-content-body,
    .vs-pick-list-container .vs-pick-list-right-panel .__panel-content-body {
      min-height: 200px;
      overflow-y: auto;
      height: 500px; }
  .vs-pick-list-container .vs-pick-list-left-panel {
    border-right: 1px solid #67c8ec; }
  .vs-pick-list-container .vs-pick-list-right-panel {
    border-left: 1px solid #67c8ec; }
  .vs-pick-list-container .vs-pick-list-middle-panel {
    margin-top: 250px;
    width: 90px;
    min-width: 90px; }
    .vs-pick-list-container .vs-pick-list-middle-panel ul {
      padding: 0; }
      .vs-pick-list-container .vs-pick-list-middle-panel ul li {
        display: block; }
        .vs-pick-list-container .vs-pick-list-middle-panel ul li .__transfer-button {
          margin: 1px;
          background: #ffffff;
          color: #387bb8;
          border: 1px solid #387bb8;
          padding: 0px 18px; }
        .vs-pick-list-container .vs-pick-list-middle-panel ul li .__transfer-button:hover {
          color: #ffffff;
          background: #387bb8; }

.vs-pick-list-ul-bottom > li {
  display: inline;
  padding-right: 10px; }

.vs-search-box.style-1 {
  position: relative;
  padding-right: 30px;
  border: 1px solid #ddd; }
  .vs-search-box.style-1 .__input {
    border: 0;
    width: 100%; }
  .vs-search-box.style-1 .__search-btn,
  .vs-search-box.style-1 .__toggle-search-adv {
    display: flex;
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center; }
  .vs-search-box.style-1 .__toggle-search-adv {
    right: 30px; }
  .vs-search-box.style-1 .__search-btn {
    right: 0;
    color: #fff;
    background-color: #aaaaaa; }

.vs-search-box.style-2 {
  position: relative; }
  .vs-search-box.style-2 .__input {
    padding-right: 29px;
    width: 100%; }
  .vs-search-box.style-2 .__search-btn,
  .vs-search-box.style-2 .__toggle-search-adv {
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    top: 0;
    cursor: pointer; }
  .vs-search-box.style-2 .__toggle-search-adv {
    right: 30px; }

.content-grid {
  background-color: white;
  display: flex; }
  .content-grid .new-grid-loading .ui-widget-overlay {
    z-index: 30;
    background-color: #fff; }
  .content-grid .table-grid {
    border-collapse: collapse; }
    .content-grid .table-grid td, .content-grid .table-grid th {
      border: #dddddd solid 1px; }
    .content-grid .table-grid thead {
      background: #eee; }
    .content-grid .table-grid tbody tr td:first-child {
      border-left: none; }
    .content-grid .table-grid tbody tr td:last-child {
      border-right: none; }

.grid-note {
  background-color: #fff;
  padding-top: 30px;
  padding-left: 50px;
  padding-bottom: 50px; }
  .grid-note .label-name {
    vertical-align: super;
    margin-left: 10px; }

.term {
  width: 7%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  z-index: 20;
  flex-direction: column;
  position: absolute;
  color: black;
  font-family: helvetica, sans-serif;
  padding: 0.5em;
  font-size: 0.9em;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in;
  -moz-transition: -moz-box-shadow 0.15s ease-in;
  -o-transition: -o-box-shadow 0.15s ease-in;
  transition: box-shadow 0.15s ease-in; }
  .term:hover {
    box-shadow: 2px 2px 10px #444;
    -o-box-shadow: 2px 2px 10px #444;
    -webkit-box-shadow: 2px 2px 10px #444;
    -moz-box-shadow: 2px 2px 10px #444;
    opacity: 0.6; }

.status-note-normal {
  background-color: #dcf2ff;
  height: 20px;
  width: 50px;
  display: inline-block; }

.term-normal {
  background-color: #dcf2ff; }

.status-note-profession {
  background-color: #ffecde;
  height: 20px;
  width: 50px;
  display: inline-block; }

.term-profession {
  background-color: #ffecde; }

.status-note-elective {
  background-color: #ffffff;
  height: 20px;
  width: 50px;
  display: inline-block; }

.term-elective {
  background-color: #ffffff; }

.status-note-normal {
  border: 1px solid #b0e2eb; }

.term-normal {
  border: 1px solid #b0e2eb; }

.status-note-profession {
  border: 1px solid #f9d1ae; }

.term-profession {
  border: 1px solid #f9d1ae; }

.status-note-elective {
  border: 1px solid #072e7b; }

.term-elective {
  border: 1px solid #072e7b; }

.note-elective {
  position: relative; }
  .note-elective:after {
    content: " ";
    width: 100%;
    height: 4px;
    background: #072e7b;
    display: block;
    position: absolute;
    bottom: 0; }

.note-key {
  position: relative; }
  .note-key:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    border: 6px solid #ff390a;
    width: 0;
    height: 0;
    border-bottom-color: transparent;
    border-left-color: transparent; }

.elective:after {
  content: " ";
  width: 100%;
  height: 4px;
  background: #072e7b;
  display: block;
  position: absolute;
  bottom: 0; }

.term-key:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  border: 6px solid #ff390a;
  width: 0;
  height: 0;
  border-bottom-color: transparent;
  border-left-color: transparent; }

.item-child {
  left: 2%;
  width: 83px; }

.point {
  position: absolute; }

.multi-select-textarea .ui-multiselect {
  width: 100%;
  min-height: 120px;
  border-radius: 0; }
  .multi-select-textarea .ui-multiselect .ui-multiselect-trigger {
    height: auto;
    top: 16px; }
  .multi-select-textarea .ui-multiselect .ui-multiselect-label.ui-corner-all {
    display: flex;
    flex-wrap: wrap; }
    .multi-select-textarea .ui-multiselect .ui-multiselect-label.ui-corner-all .item-choose {
      width: 48%;
      color: #000;
      background-color: #d9ebf7;
      display: flex;
      border: 1px solid #dddddd;
      border-radius: 0;
      margin-right: 10px;
      line-height: 1.4;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between; }
    .multi-select-textarea .ui-multiselect .ui-multiselect-label.ui-corner-all .full-width {
      width: 100%;
      margin: 3px auto;
      padding-left: 5px; }
  .multi-select-textarea .ui-multiselect .ui-multiselect-panel {
    display: none; }

.vs-multi-select-with-modal .ui-multiselect {
  width: 100%;
  min-height: 80px;
  border-radius: 0; }

.vs-multi-select-with-modal .ui-multiselect-trigger {
  top: 0;
  right: 0; }

.vs-multi-select-with-modal .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
  top: 5px;
  right: 5px;
  left: auto;
  margin: 0;
  font-size: 16px; }

.vs-multi-select-with-modal .ui-multiselect-label.ui-corner-all {
  display: flex;
  flex-wrap: wrap; }

.vs-multi-select-with-modal .selected-item {
  border: 1px solid #1ca7ff;
  background-color: rgba(28, 167, 255, 0.2);
  padding: 0 25px 0 5px;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  position: relative; }
  .vs-multi-select-with-modal .selected-item .__icon {
    position: absolute;
    width: 20px;
    line-height: 20px;
    top: 0;
    text-align: center;
    right: 0;
    color: #999; }

.vs-multi-select-with-modal .ui-multiselect-panel {
  display: none; }

.vs-table-calendar-container .__top-table-action .__right-content {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }
  .vs-table-calendar-container .__top-table-action .__right-content .__current-time,
  .vs-table-calendar-container .__top-table-action .__right-content .__navigation,
  .vs-table-calendar-container .__top-table-action .__right-content .__view-type {
    display: inline-flex;
    padding: 0 7.5px;
    align-items: center;
    margin-bottom: 5px; }
  .vs-table-calendar-container .__top-table-action .__right-content .__current-time,
  .vs-table-calendar-container .__top-table-action .__right-content .__navigation {
    border-right: 1px solid #999999; }
  .vs-table-calendar-container .__top-table-action .__right-content .__navigation .vs-btn .__icon {
    font-size: 20px; }
  .vs-table-calendar-container .__top-table-action .__right-content .__change-view .vs-btn {
    margin-bottom: 0; }

.vs-table-calendar-not-hover .ui-table .ui-table-tbody > tr,
.vs-table-calendar-not-hover .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #fff; }
  .vs-table-calendar-not-hover .ui-table .ui-table-tbody > tr:hover,
  .vs-table-calendar-not-hover .ui-table .ui-table-tbody > tr:nth-child(even):hover {
    background-color: #fff; }

.vs-table-calendar thead.ui-table-thead {
  background: #eee; }

.vs-table-calendar .ui-table .ui-table-thead > tr > th {
  padding: 0; }

.vs-table-calendar .ui-table .ui-table-tbody > tr > td.__room-info {
  background-color: #a4d1f1; }

.vs-table-calendar .ui-table .ui-table-tbody > tr > td.__data-month {
  padding: 0;
  vertical-align: top; }

.vs-table-calendar td.__data-month .__day {
  text-align: right;
  font-size: 16px;
  padding: 8px 10px; }

.vs-table-calendar td.__data-month .__period-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .vs-table-calendar td.__data-month .__period-info .__period {
    color: #418e42;
    font-weight: normal;
    font-size: inherit;
    padding-left: 3px;
    width: 30%; }

.vs-table-calendar td.__data-month .__room-info {
  background-color: #a4d1f1;
  margin-bottom: 3px;
  padding: 5px; }
  .vs-table-calendar td.__data-month .__room-info:last-child {
    margin-bottom: 0; }

.vs-table-calendar .ui-table .ui-table-thead > tr > th,
.vs-table-calendar .ui-table .ui-table-tfoot > tr > td {
  border-color: #bbb; }

.vs-table-calendar .__day_of_week {
  display: flex;
  align-items: center; }
  .vs-table-calendar .__day_of_week .__left,
  .vs-table-calendar .__day_of_week .__right {
    width: 50%;
    padding: 10px; }
    .vs-table-calendar .__day_of_week .__left.__header-day,
    .vs-table-calendar .__day_of_week .__right.__header-day {
      width: auto; }
  .vs-table-calendar .__day_of_week .__left {
    font-size: 22px;
    color: #333333; }

.vs-table-calendar .__period-vs-day {
  position: relative;
  height: 70px; }
  .vs-table-calendar .__period-vs-day .__stroke {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute; }
    .vs-table-calendar .__period-vs-day .__stroke line {
      stroke: #bbb;
      stroke-width: 1; }
  .vs-table-calendar .__period-vs-day .__period,
  .vs-table-calendar .__period-vs-day .__day {
    height: 50%;
    padding: 10px;
    line-height: 15px;
    font-weight: bold;
    color: #000; }
  .vs-table-calendar .__period-vs-day .__period {
    text-align: left; }
  .vs-table-calendar .__period-vs-day .__day {
    text-align: right; }

.vs-table-calendar td .__period {
  font-size: 16px;
  color: #333333;
  font-weight: bold; }

.vs-table-calendar-room .ui-table .ui-table-thead > tr > th {
  color: #000; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__data-room {
  padding: 0; }

.vs-table-calendar-room .__day-of-week {
  font-size: 18px; }

.vs-table-calendar-room .__period-info {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 30px; }
  .vs-table-calendar-room .__period-info.__period-info-dhcq {
    background-color: #bfd6f0; }
  .vs-table-calendar-room .__period-info.__period-info-sdh {
    background-color: #f0c8bf; }
  .vs-table-calendar-room .__period-info.__period-info-vlvh {
    background-color: #fff799; }
  .vs-table-calendar-room .__period-info.__period-info-data {
    background-color: #3a8ce9; }
  .vs-table-calendar-room .__period-info.__period-info-exist {
    border: none; }
    .vs-table-calendar-room .__period-info.__period-info-exist .__icon {
      color: #0084ff;
      display: none; }
    .vs-table-calendar-room .__period-info.__period-info-exist:hover .__icon {
      display: inline; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-dhcq {
  background-color: #bfd6f0;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-sdh {
  background-color: #f0c8bf;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-vlvh {
  background-color: #fff799;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-data {
  background-color: #3a8ce9;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-choose {
  background-color: #3a8ce9;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-drag {
  background-color: #82ca9c;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-busy {
  background-color: #bbbbbb;
  position: relative; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-drag {
  cursor: pointer; }

.vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-data {
  color: #fff;
  font-weight: bold;
  cursor: pointer; }
  .vs-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-data.active:after {
    content: "\f058";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 0;
    top: 0; }

.__period-active {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  background-color: #3a8ce9;
  border: none;
  outline: none; }

.vs-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-tick {
  cursor: pointer; }

.vs-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-busy {
  background-color: #d9d9d9;
  position: relative; }

.vs-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-click {
  background-color: #92d050;
  position: relative; }

.vs-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-selected {
  background-color: #eb8c43;
  position: relative; }

.vs-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-suggested {
  background-color: #73bf8b;
  position: relative; }

.vs-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-suggestions {
  background-color: #fcf88d;
  position: relative; }

.vs-document-file {
  background: #eeeeee;
  padding: 0;
  position: relative;
  width: 180px;
  max-width: 180px;
  margin: 10px;
  flex-basis: auto; }
  .vs-document-file .__document-image {
    position: relative;
    padding: 5px; }
    .vs-document-file .__document-image .__image {
      width: 100%;
      background-repeat: no-repeat;
      height: 200px;
      background-size: cover; }
  .vs-document-file .__document-meta {
    color: #000;
    text-align: left;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    -webkit-transition: backgroud 0.3s ease-out;
    -o-transition: backgroud 0.3s ease-out;
    transition: backgroud 0.3s ease-out; }
    .vs-document-file .__document-meta .__icon {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #2e9fe0;
      -webkit-transition: backgroud 0.3s ease-out;
      -o-transition: backgroud 0.3s ease-out;
      transition: backgroud 0.3s ease-out; }
    .vs-document-file .__document-meta .__title {
      margin-left: 5px; }
  .vs-document-file .__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .vs-document-file .__overlay .__icon {
      display: block;
      color: #fff;
      text-align: center;
      cursor: pointer;
      margin: 15px; }
  .vs-document-file:hover .__overlay {
    opacity: 1; }
  .vs-document-file:hover .__document-meta {
    background-color: #0084ff;
    color: #fff; }
    .vs-document-file:hover .__document-meta .__icon {
      background-color: #fff; }

.task_screen_body {
  height: calc(100vh - 140px);
  background-color: #fff;
  overflow: auto; }

.vs-overlay {
  position: relative; }
  .vs-overlay .vs-overlay-btn {
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 13px;
    color: #000;
    padding: 5px 15px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out; }
    .vs-overlay .vs-overlay-btn:hover, .vs-overlay .vs-overlay-btn.active {
      background-color: #ececec; }
  .vs-overlay .vs-overlay-popup {
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    width: auto;
    height: auto;
    background-color: #fff;
    transition: all 0.3s ease;
    z-index: 10;
    border-radius: 5px;
    top: auto;
    left: auto; }
    .vs-overlay .vs-overlay-popup.active {
      opacity: 1;
      visibility: visible;
      width: max-content;
      height: auto; }

.task-tab-content {
  height: calc(100vh - 141px);
  display: flex;
  flex-direction: column; }
  .task-tab-content-toolbar {
    background-color: #fff;
    padding: 7.5px 0 0;
    margin-bottom: 7.5px;
    position: relative; }
  .task-tab-content-body {
    background-color: #fff;
    height: calc(100vh - 189px);
    overflow: auto; }

table .avatar {
  display: flex;
  align-items: center;
  gap: 8px; }
  table .avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%; }

.box_html_content img {
  max-width: 100%; }

.avatar_user {
  width: 30px;
  height: 30px;
  margin-top: 0;
  border: 2px #eaeaea solid;
  border-radius: 50%; }

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15; }

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff; }

.emoji-mart .emoji-mart-emoji {
  padding: 6px; }

.emoji-mart-bar {
  border: 0 solid #d9d9d9; }

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  color: #858585;
  line-height: 0; }

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color .1s ease-out; }

.emoji-mart-anchor:hover,
.emoji-mart-anchor-selected {
  color: #464646; }

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0; }

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646; }

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px; }

.emoji-mart-anchors svg {
  fill: currentColor;
  max-height: 18px; }

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */ }

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px; }

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: .2em .6em;
  border-radius: 25px;
  border: 1px solid #d9d9d9;
  outline: 0; }

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default; }

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%; }

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95); }

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0; }

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585; }

.emoji-mart-no-results .emoji-mart-category-label {
  display: none; }

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em; }

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none; }

.emoji-mart-preview {
  position: relative;
  height: 70px; }

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.emoji-mart-preview-emoji {
  left: 12px; }

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all; }

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right; }

.emoji-mart-preview-name {
  font-size: 14px; }

.emoji-mart-preview-shortnames {
  font-size: 12px;
  color: #888; }

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: .5em; }

.emoji-mart-preview-emoticons {
  font-size: 11px;
  color: #bbb; }

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle; }

.emoji-mart-title .emoji-mart-emoji {
  padding: 0; }

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300; }

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff; }

.emoji-mart-skin-swatches-opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px; }

.emoji-mart-skin-swatches-opened .emoji-mart-skin-swatch-selected:after {
  opacity: .75; }

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: .125s;
  transition-timing-function: ease-out; }

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s; }

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: .03s; }

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: .06s; }

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: .09s; }

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: .12s; }

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: .15s; }

.emoji-mart-skin-swatch-selected {
  position: relative;
  width: 16px;
  padding: 0 2px; }

.emoji-mart-skin-swatch-selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease-out; }

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%; }

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a; }

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc; }

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95; }

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68; }

.emoji-mart-skin-tone-5 {
  background-color: #9b643d; }

.emoji-mart-skin-tone-6 {
  background-color: #594539; }

.form-control-feedback {
  margin-top: -20px; }

a {
  cursor: pointer; }

.order {
  padding: 5px;
  border-radius: 5px;
  color: #FFF; }

.don-moi {
  color: #000;
  font-weight: bold; }

.don-danggiao {
  color: #1ca7ff;
  font-weight: bold; }

.don-thatbai {
  color: red;
  font-weight: bold; }

.don-thanhcong {
  color: #14b545;
  font-weight: bold; }

.don-nguyhiem {
  color: #f89854;
  font-weight: bold; }

body .ui-table .ui-table-tbody tr .rate {
  display: none; }

body .ui-table .ui-table-tbody tr:hover .rate {
  display: block; }

body .ui-table .ui-table-tbody tr:hover .rate-labe {
  display: none; }

body .ui-table .ui-table-tbody tr .actions {
  opacity: 0;
  position: absolute;
  right: 0;
  background: #FFF;
  top: 11px;
  border-radius: 50px; }

body .ui-table .ui-table-tbody tr:hover .actions {
  opacity: 1; }

body .ui-table .ui-table-tbody tr:hover .actions-labe {
  opacity: 0; }

.bold {
  font-weight: bold; }

.blueFont {
  color: #FF5722; }

.alepay-erorr {
  color: red; }

.alepay-success {
  color: #14b545; }

.alepay-review {
  color: #f89854;
  cursor: pointer; }

.connect-partner-info fieldset {
  background: cornsilk;
  margin: 10px; }
  .connect-partner-info fieldset legend {
    font-weight: bold;
    background: antiquewhite !important; }
