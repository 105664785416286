/* You can add global styles to this file, and also import other style files */

@import './styles/variables';
@import './styles/helper/mixin';
@import './styles/core';
@import './styles/helper/helper';
@import './styles/element/element';
@import './styles/module/module';
@import './styles/task';
@import "primeicons/primeicons.css";
@import "~@ctrl/ngx-emoji-mart/picker";

.form-control-feedback {
    margin-top: -20px;
}

a {
    cursor: pointer;
}

.order {
    padding: 5px;
    border-radius: 5px;
    color: #FFF;
}

.don-moi {
    color: #000;
    font-weight: bold;
}

.don-danggiao {
    color: #1ca7ff;
    font-weight: bold;
}

.don-thatbai {
    color: red;
    font-weight: bold;
}

.don-thanhcong {
    color: #14b545;
    font-weight: bold;
}

.don-nguyhiem {
    color: #f89854;
    font-weight: bold;
}

body .ui-table .ui-table-tbody {
    tr .rate {
        display: none;
    }
    tr:hover {
        .rate {
            display: block;
        }
        .rate-labe {
            display: none;
        }
    }
    tr .actions {
        opacity: 0;
        position: absolute;
        right: 0;
        background: #FFF;
        top: 11px;
        border-radius: 50px;
    }
    tr:hover {
        .actions {
            opacity: 1;
        }
        .actions-labe {
            opacity: 0;
        }
    }
}

.bold {
    font-weight: bold;
}

.blueFont {
    color: #FF5722;
}

.alepay-erorr {
    color: red;
}

.alepay-success {
    color: #14b545;
}

.alepay-review {
    color: #f89854;
    cursor: pointer;
}

.connect-partner-info fieldset {
    background: cornsilk;
    margin: 10px;
    legend {
        font-weight: bold;
        background: antiquewhite!important;
    }
}